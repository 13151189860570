import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";
import SignupBg from "../Images/SignupBg.png";
import PayaplImg from "../Images/PayPal.png";
import logo from "../Images/LogoWithText.png";
import { Loader2 } from "lucide-react";
import { PaymentButtonRadio } from "../Components/PaymentOptions";
import { checkoutAppearance } from "../data/signupData";
import { getBrowserInformation } from "../utils/getBrowserInfo";
import { calculateTimeLeft } from "../utils/calculateTimeLeft";
import StripeCheckoutSkeleton from "../Skeleton/StripeCheckoutSkeletion";
import PMRadioSkeleton from "../Skeleton/PMRadioSkeleton";
import PriceHeaderSkeleton from "../Skeleton/PriceHeaderSkeleton";
import { v4 as uuidv4 } from 'uuid';
const ENV = process.env.REACT_APP_ENV;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
  locale: "fr",
});
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const PaymentComponent = ({
  onPaymentSuccess,
  disabled,
  clientSecret,
  email,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const [updatedClientSecret, setUpdatedClientSecret] = useState("");

  const updateCheckoutPaymentIntent = async (newEmail) => {
    if (!clientSecret) return;

    try {
      const functions = getFunctions();
      const updateIntent = httpsCallable(
        functions,
        "updateCheckoutPaymentIntent"
      );

      const result = await updateIntent({
        email: newEmail,
        paymentIntentId: clientSecret.split("_secret")[0],
        isTrail: "false",
        isUser: "true",
      });

      if (result.data.error) throw new Error(result.data.error);
      setUpdatedClientSecret(result.data.clientSecret);

      return result.data.clientSecret;
    } catch (err) {
      console.log("Error: ", err);
      toast.error("L'e-mail existe déjà");
    }
  };
  const handlePaymentAndSignup = async () => {
    if (!stripe || !elements) return;
    setProcessing(true);
    const updatedCSecrete = await updateCheckoutPaymentIntent(email);

    if (updatedCSecrete) {
      try {
        const { error: submitError } = await elements.submit();
        if (submitError) throw new Error(submitError.message);

        if (window && ENV === "production") {
          window.lintrk("track", { conversion_id: 1895034 });
        }

        const paymentResult = await stripe.confirmPayment({
          elements,
          updatedClientSecret,
          confirmParams: {
            return_url: `${REACT_APP_BASE_URL}/payment-success`,
          },
          redirect: "if_required",
        });

        if (paymentResult.error) throw new Error(paymentResult.error.message);

        if (paymentResult.paymentIntent.status === "succeeded") {
          onPaymentSuccess(paymentResult.paymentIntent, clientSecret);
        }
      } catch (err) {
        setError(err.message);
        toast.error("Le paiement a échoué. Merci de réessayer.");
      } finally {
        setProcessing(false);
      }
    } else {
      setProcessing(false);
    }
  };

  return (
    <div className="relative">
      <PaymentElement />
      <button
        onClick={handlePaymentAndSignup}
        disabled={disabled || !stripe || processing}
        className="w-full mt-2 py-3 px-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-[#2952F0] via-[#4268FF] to-[#113BE1] bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
      >
        {processing ? "Processing..." : "Finaliser le paiement"}
      </button>
      {error && <div className="text-red-500 mt-2">{error}</div>}
    </div>
  );
};
const UserInfoForm = ({
  onNext,
  register,
  errors,
  password,
  isValid,
  isLoading,
}) => (
  <form className="flex flex-col gap-4 mb-2 font-poppins">
    <div className="grid grid-cols-2 gap-4">
      <div>
        <label
          htmlFor="prénom"
          className="block text-start text-sm text-gray-300 mb-1"
        >
          Prénom
        </label>
        <input
          id="prénom"
          type="text"
          {...register("firstName", { required: "Le prénom est requis" })}
          className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
          placeholder="Votre prénom"
        />
        {errors.firstName && (
          <p className="text-red-500 text-sm mt-1">
            {errors.firstName.message}
          </p>
        )}
      </div>
      <div>
        <label
          htmlFor="nom"
          className="block text-start text-sm text-white mb-1"
        >
          Nom
        </label>
        <input
          id="nom"
          type="text"
          {...register("lastName", { required: "Le nom est requis" })}
          className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
          placeholder="Votre nom"
        />
        {errors.lastName && (
          <p className="text-red-500 text-sm mt-1">{errors.lastName.message}</p>
        )}
      </div>
    </div>
    <div>
      <label
        htmlFor="email"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Adresse mail
      </label>
      <input
        id="email"
        type="email"
        placeholder="email@domain.com"
        {...register("email", {
          required: "L'email est obligatoire",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Adresse email invalide",
          },
        })}
        disabled
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
      {errors.email && (
        <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
      )}
    </div>
    <div>
      <label
        htmlFor="password"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Mot de passe
      </label>
      <input
        id="password"
        type="password"
        placeholder="******"
        {...register("password", {
          required: "Le mot de passe est obligatoire",
          minLength: {
            value: 6,
            message: "Le mot de passe doit comporter au moins 6 caractères",
          },
        })}
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
      {errors.password && (
        <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>
      )}
    </div>
    <div>
      <label
        htmlFor="confirmPassword"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Confirmer le mot de passe
      </label>
      <input
        id="confirmPassword"
        type="password"
        placeholder="******"
        {...register("confirmPassword", {
          required: "La confirmation du mot de passe est obligatoire",
          validate: (value) =>
            value === password || "Les mots de passe ne correspondent pas",
        })}
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
      {errors.confirmPassword && (
        <p className="text-red-500 text-sm mt-1">
          {errors.confirmPassword.message}
        </p>
      )}
    </div>
    <div>
      <label
        htmlFor="phone"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Numéro de téléphone (optionnel)
      </label>
      <input
        id="phone"
        type="tel"
        placeholder="+33 XX XX XX XX"
        {...register("phoneNumber")}
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
    </div>
    <button
      onClick={onNext}
      disabled={!isValid || isLoading}
      className={`
        w-full py-3 px-4 mt-4
        border border-transparent rounded-md 
        shadow-sm text-sm font-medium text-white 
        bg-gradient-to-r from-[#2952F0] via-[#4268FF] to-[#113BE1]
        bg-indigo-600 hover:bg-indigo-700 
        focus:outline-none focus:ring-4 focus:ring-indigo-500/50 
        transition-all duration-300
        ${isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-indigo-700"}
      `}
    >
      {isLoading ? (
        <div className="flex items-center  justify-center">
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Chargement...
        </div>
      ) : (
        "Enregistrez votre compte"
      )}
    </button>
  </form>
);
const Checkout = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isComponentLoading, setIsComponentLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [priceData, setPriceData] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("stripe");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [checkoutDiscountPrice, setCheckoutDiscountPrice] = useState(null);
  const [successPayementIntent, setSuccessPayementIntent] = useState("");
  const [successClientSecrete, setSuccessClientSecrete] = useState("");
  const [email, setEmail] = useState("");
  const { id } = useParams();
  const browserInfo = getBrowserInformation();
  const navigate = useNavigate();
  const uuid = uuidv4()
  const {
    register,
    formState: { errors, isValid },
    watch,
    trigger,
    setValue,
  } = useForm({ mode: "onChange" });

  const fetchDisocuntPrice = async (discountId) => {
    console.log("DiscountId, From fetch function: ", discountId);
    const discountDocRef = doc(db, "checkoutDiscounts", discountId);
    const dicountPrice = await getDoc(discountDocRef);

    console.log("Discount Data: ", dicountPrice.data());

    setCheckoutDiscountPrice(dicountPrice.data()?.price);
    return dicountPrice.data()?.price
  };


  const watchedFields = watch();
  const password = watch("password");

  const createCheckoutPaymentIntent = async (price, id) => {
    console.log("Price: ", price);
    setIsComponentLoading(true);
    try {

      const functions = getFunctions();
      const createCheckoutIntent = httpsCallable(
        functions,
        "createCheckoutPaymentIntent"
      );

      const result = await createCheckoutIntent({
        totalPrice: parseFloat(price),
        currency: "EUR",
        checkoutDiscountLinkId: id,
        browserInfo,
      });

      if (result.data.error) throw new Error(result.data.error);

      setClientSecret(result.data.clientSecret);
      setIsComponentLoading(false);
      setLoading(false);
    } catch (err) {
      toast.error(
        "Erreur lors de l'initialisation du paiement. Veuillez réessayer."
      );
      setIsComponentLoading(false);
      setLoading(false);
      return false;
    }
    return true;
  };

  const handleNextStep = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const isValid = await trigger();
    if (isValid) {
      handleFormSubmit(successPayementIntent, successClientSecrete);
    }
    setIsSubmitting(false);
  };

  const fetchPriceData = async () => {
    try {
      setLoading(true);
      if (id) {
        const price = await fetchDisocuntPrice(id)
        createCheckoutPaymentIntent(price, id);

      } else {
        const priceDocRef = collection(db, "coursePrice");
        const priceDocSnap = await getDocs(priceDocRef);
        if (priceDocSnap.docs[0].data()) {
          createCheckoutPaymentIntent(
            priceDocSnap.docs[0].data().discountedPrice
          );
          setPriceData({
            id: priceDocSnap.docs[0].id,
            ...priceDocSnap.docs[0].data(),
          });

        }
      }

    } catch (error) {
      console.error("Error fetching price data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPriceData();
  }, [id]);

  const handlePaymentSuccess = async (paymentIntent, client_secret) => {
    try {
      setPaymentCompleted(true);
      toast.success("Paiement réussi! Veuillez compléter votre inscription.");
      setSuccessPayementIntent(paymentIntent);
      setSuccessClientSecrete(client_secret);
      setValue("email", email);
      setCurrentStep(2);
    } catch (error) {
      toast.error(
        "Une erreur s'est produite après le paiement. Veuillez contacter le support."
      );
    }
  };
  const handleFormSubmit = async () => {
    setIsSubmitting(true);
    const isValid = await trigger();
    if (!isValid) {
      setIsSubmitting(false);
      return;
    }

    try {
      const userData = {
        firstName: watchedFields.firstName,
        lastName: watchedFields.lastName,
        email: watchedFields.email,
        password: watchedFields.password,
        phoneNumber: watchedFields.phoneNumber || "",
        fullName: `${watchedFields.firstName} ${watchedFields.lastName}`,
      };
      setIsSubmitting(true);
      const functions = getFunctions();
      const registerCheckoutUser = httpsCallable(
        functions,
        "registerCheckoutUser"
      );

      const result = await registerCheckoutUser({
        clientSecret: successClientSecrete,
        paymentIntent: successPayementIntent,
        userData,
      });

      if (result.data.success) {
        setIsSubmitting(false);
        toast.success("Inscription réussie!");
        navigate("/payment-success");
      } else {
        throw new Error(result.data.error || "Une erreur est survenue");
      }
    } catch (error) {
      toast.error(
        error.message || "Erreur lors de l'inscription. Veuillez réessayer."
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  // const handlePaypalPayment = async () => {
  //   setLoading(true);
  //   const userData = {
  //     email: email,
  //   };

  //   try {
  //     const functions = getFunctions();
  //     const createCheckoutPaypalOrder = httpsCallable(
  //       functions,
  //       "createCheckoutPaypalOrder"
  //     );

  //     await createCheckoutPaypalOrder({
  //       userData,
  //       currency: "EUR",
  //       browserInfo,
  //     }).then((res) => {
  //       setLoading(false);
  //       const url = res.data.url;
  //       window.open(url, "_self");
  //     });
  //   } catch (err) {
  //     toast.error(
  //       "Erreur lors de la mise à jour des informations de paiement. Veuillez réessayer."
  //     );
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const loader = "auto";

  return (
    <div
      className="min-h-screen h-fit w-full flex flex-col bg-[#00061E] bg-cover bg-no-repeat pt-6 items-center"
      style={{ backgroundImage: `url(${SignupBg})` }}
    >
      <div className="flex items-center gap-2">
        <img src={logo} width={140} height={130} alt="Logo" />
        {ENV === "staging" && (
          <div className="text-sm text-gray-500">{ENV}</div>
        )}
      </div>

      <div className="w-[90%] lg:w-[85%] py-4">
        <div className="flex flex-col items-center gap-10 min-w-[100%]">
          <div className="w-full md:w-[75%] lg:w-[45%] px-2 h-full">
            <div className="">
              <div className="rounded-lg bg-[#ffffff12] pt-4 form-card">
                <div className="p-3 md:px-10 py-6 ">
                  {currentStep === 1 ? (
                    <>
                      {clientSecret ? (
                        <>
                          <div className="text-start bg-slate-800 p-4 py-3 rounded-lg font-semibold text-sm md:text-lg text-gray-300 mb-4 flex justify-between">
                            <div>Formation Chatgpt Academy</div>
                            <div className="w-[90px]">
                              {id ? checkoutDiscountPrice + " €" :
                                `${priceData?.actualPrice ||
                                priceData?.totalPrice
                                } €`
                              }
                            </div>
                          </div>
                          <div className="mb-4">
                            <label
                              htmlFor="email"
                              className="block text-start text-sm text-gray-300 mb-1"
                            >
                              Adresse mail
                            </label>
                            <input
                              onChange={(e) => setEmail(e.target.value)}
                              id="email"
                              type="email"
                              value={email}
                              required
                              placeholder="email@domain.com"
                              className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
                            />
                          </div>
                        </>
                      ) : (
                        <PriceHeaderSkeleton />
                      )}

                      <div>


                        {selectedPaymentMethod === "stripe" && (
                          <>
                            {clientSecret ? (
                              <Elements
                                stripe={stripePromise}
                                options={{
                                  clientSecret,
                                  appearance: checkoutAppearance,
                                  loader,
                                  fields: {
                                    billingDetails: {
                                      address: {
                                        country: "never",
                                      },
                                    },
                                  },
                                }}
                              >
                                <PaymentComponent
                                  userDetails={watchedFields}
                                  onPaymentSuccess={handlePaymentSuccess}
                                  disabled={!isValid || loading}
                                  clientSecret={clientSecret}
                                  email={email}
                                />
                              </Elements>
                            ) : (
                              <StripeCheckoutSkeleton />
                            )}
                          </>
                        )}

                        {/* {selectedPaymentMethod === "paypal" && (

                          <div className="w-full rounded-lg text-white text-center mt-4"> */}
                        {/* <div>
                              <StripeBuyButton uuid={uuid} email={email} isUserData={false} />
                            </div> */}
                        {/* <div className="my-4 font-bold flex items-center">
                              <div className="w-full h-[1px] bg-[#bababa]">

                              </div>
                              <div className="mx-4">OU</div>
                              <div className="w-full h-[1px] bg-[#bababa]"></div>
                            </div> */}
                        {/* <button
                              onClick={() => handlePaypalPayment()}
                              disabled={loading}
                              className="flex items-center w-full h-[55px] disabled:opacity-50 disabled:cursor-not-allowed py-4 mb-2 rounded-lg bg-[#FFD401] justify-center text-center gap-2 cursor-pointer"
                            >
                              {loading ? (
                                <div className="flex justify-center items-center">
                                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-[#003087]"></div>
                                </div>
                              ) : (
                                <img
                                  src={PayaplImg}
                                  className="h-[30px] w-[150px]"
                                  alt="paypal-button"
                                />
                              )}
                            </button>
                          </div>
                        )} */}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex items-center gap-2 mb-6">
                        <h1 className="text-start font-bold text-2xl text-gray-300">
                          Informations personnelles
                        </h1>
                      </div>
                      <UserInfoForm
                        isValid={isValid}
                        onNext={handleNextStep}
                        register={register}
                        errors={errors}
                        password={password}
                        isLoading={isSubmitting}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
