import React from 'react'
import Header from '../Components/Header'
// import Stats from '../Components/Stats'
import Course from './Course'


const Home = ({ isSidebarOpen, setIsSidebarOpen }) => {
  return (
    <div className="flex flex-col h-screen">
      <div className="w-[100%]">
        <Header PageName={"Home"} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      </div>
      <div className='w-full flex items-start bg-primary rounded-sm h-full border border-[#fefefe20]'>
        {/* <Stats /> */}
        <Course />
      </div>

    </div>
  )
}

export default Home
