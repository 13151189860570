import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where, Timestamp } from 'firebase/firestore';
import { db } from '../firebase/config';
import { Search } from 'lucide-react';
import Header from './Header';

// Stat Card Component with themed colors
const StatCard = ({ icon: Icon, title, value, iconColor = "bg-primaryBlue/20", textColor = "text-primaryBlue" }) => (
  <div className="bg-white/10 backdrop-blur-sm rounded-sm border border-[#fefefe20]  p-6 hover:shadow-lg transition-shadow duration-200">
    <div className="flex items-center space-x-4">
      <div className={`p-3 rounded-full ${iconColor}`}>
        <Icon className={`w-6 h-6 ${textColor}`} />
      </div>
      <div>
        <p className="text-sm font-medium text-white/60">{title}</p>
        <h3 className="text-2xl text-left font-bold text-white">{value}</h3>
      </div>
    </div>
  </div>
);

// Progress Bar Component with themed colors
const ProgressBar = ({ progress }) => (
  <div className="flex items-center space-x-2">
    <div className="flex-1 h-2 bg-white/10 rounded-full overflow-hidden">
      <div
        className="h-full bg-primaryBlue rounded-full transition-all duration-300"
        style={{ width: `${progress}%` }}
      />
    </div>
    <span className="text-sm font-medium text-white/80 min-w-[3rem]">
      {progress}%
    </span>
  </div>
);

// Pagination Component
const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="flex items-center justify-center px-6 py-4 bg-primary opacity-75 border-t border-[#fefefe20]">
    <button
      onClick={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 1}
      className="px-4 py-2 text-xl font-medium text-white/60 disabled:opacity-50 disabled:cursor-not-allowed hover:text-white transition-colors"
    >
      &lt;
    </button>
    <span className="text-sm text-white/60">
      Page {currentPage} of {totalPages}
    </span>
    <button
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
      className="px-4 py-2 text-xl font-medium text-white/60 disabled:opacity-50 disabled:cursor-not-allowed hover:text-white transition-colors"
    >
      &gt;
    </button>
  </div>
);

// Main Dashboard Component
const UserStats = ({ setIsSidebarOpen, isSidebarOpen }) => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    averageProgress: 0,
    totalContent: 0
  });
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const [usersSnapshot, progressSnapshot, quizzesSnapshot, videosSnapshot] =
        await Promise.all([
          getDocs(collection(db, 'users')),
          getDocs(collection(db, 'userProgress')),
          getDocs(collection(db, 'quizes')),
          getDocs(collection(db, 'videos'))
        ]);

      // Process content items
      const quizzes = quizzesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      const videos = videosSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      const progressMap = new Map(
        progressSnapshot.docs.map(doc => [doc.id, doc.data()])
      );
      const thirtyDaysAgo = Timestamp.fromDate(
        new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
      );

      const userData = usersSnapshot.docs.map(doc => {
        const user = { id: doc.id, ...doc.data() };
        const progress = progressMap.get(user.id) || { completedItems: [] };
        const totalItems = quizzes.length + videos.length;
        const completedCount = progress.completedItems?.length || 0;

        return {
          ...user,
          progress: totalItems > 0 ? Math.round((completedCount / totalItems) * 100) : 0,
          completedItems: progress.completedItems || []
        };
      });

      const activeUsersCount = userData.filter(
        user => user.lastSignin && user.lastSignin > thirtyDaysAgo
      ).length;

      setStats({
        totalUsers: userData.length,
        activeUsers: activeUsersCount,
        averageProgress: Math.round(
          userData.reduce((acc, user) => acc + user.progress, 0) / userData.length
        ),
        totalContent: quizzes.length + videos.length
      });

      setUsers(userData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setLoading(false);
    }
  };

  // Filter users based on search term
  const filteredUsers = users.filter(user => {
    const searchLower = searchTerm.toLowerCase();
    return (
      (user.pseudo || user.displayName || '').toLowerCase().includes(searchLower) ||
      user.email.toLowerCase().includes(searchLower)
    );
  });

  // Calculate pagination
  const totalPages = Math.ceil(filteredUsers.length / recordsPerPage);
  const startIndex = (currentPage - 1) * recordsPerPage;
  const paginatedUsers = filteredUsers.slice(startIndex, startIndex + recordsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  return (
    <div className="rounded-sm  font-poppins  mx-0">
      <div className="w-[100%]">
        <Header PageName={"User Stats"} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      </div>
      <div className="mx-auto space-y-4">
        {
          loading ? <div className="rounded-sm h-[90vh] flex justify-center items-center bg-primary opacity-50  font-poppins">
            <div className="animate-spin w-16 h-16 border-4 border-primaryBlue border-t-transparent rounded-full" />
          </div> : <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <StatCard
                icon={(props) => (
                  <svg {...props} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                      d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                )}
                title="Total Users"
                value={stats.totalUsers}
                iconColor="bg-purple-500/20"
                textColor="text-purple-500"
              />
              <StatCard
                icon={(props) => (
                  <svg {...props} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                      d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                  </svg>
                )}
                title="Active Users (30d)"
                value={stats.activeUsers}
                iconColor="bg-green-500/20"
                textColor="text-green-500"
              />
              <StatCard
                icon={(props) => (
                  <svg {...props} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                  </svg>
                )}
                title="Avg. Progress"
                value={`${stats.averageProgress}%`}
                iconColor="bg-yellow-500/20"
                textColor="text-yellow-500"
              />
              <StatCard
                icon={(props) => (
                  <svg {...props} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                      d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                  </svg>
                )}
                title="Total Content"
                value={stats.totalContent}
                iconColor="bg-rose-500/20"
                textColor="text-rose-500"
              />
            </div>
            <div className="bg-primary opacity-75 rounded-sm  overflow-hidden">
              <div className="p-3 px-4 border-b header-container border-[#fefefe23]">
                <div className="flex justify-between items-center">
                  <h2 className="text-lg font-semibold text-white">User Progress Details</h2>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCurrentPage(1);
                      }}
                      className="pl-10 pr-4 py-2 bg-white/5 border border-borderColor rounded-sm text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-primaryBlue/50"
                    />
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white/40 w-4 h-4" />
                  </div>
                </div>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-white/5">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                        Email
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                        Progress
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                        Completed Items
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                        Score
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                        Last Sign In
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-[#fefefe18]">
                    {paginatedUsers.map(user => (
                      <tr key={user.id} className="hover:bg-white/5">
                        <td className="px-6 py-4 text-left whitespace-nowrap">
                          <div className="text-sm font-medium text-white">
                            {user.pseudo || user.displayName}
                          </div>
                        </td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">
                          <div className="text-sm text-white/60">{user.email}</div>
                        </td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">
                          <ProgressBar progress={user.progress} />
                        </td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">
                          <div className="text-sm text-white/60">
                            {user.completedItems.length} / {stats.totalContent}
                          </div>
                        </td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">
                          <div className="text-sm text-white/60">
                            {parseFloat(user.totalScore).toFixed(2)}
                          </div>
                        </td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">
                          <div className="text-sm text-white/60">
                            {user.lastSignin
                              ? new Date(user.lastSignin.seconds * 1000).toLocaleDateString()
                              : 'Never'
                            }
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </>
        }

      </div>
    </div>
  );
};

export default UserStats;