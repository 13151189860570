import React, { useState, useEffect } from "react";
import { useFirebase } from "../context/firebaseContext";
import toast from "react-hot-toast";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { v4 as uuidv4 } from "uuid";
const ENV = process.env.REACT_APP_ENV;
const CheckoutDiscountLinkForm = ({ checkoutDiscountLink, onClose }) => {
  const [formData, setFormData] = useState({
    title: "",
    price: null,
    checkoutDiscountUrl: "",
    randomId: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { updateCheckoutDiscountLink, addCheckoutDiscountLink } = useFirebase();
  useEffect(() => {
    // Generate URL with random ID
    const randomId = uuidv4().slice(0, 20);
    // Generate a 20-character random ID
    const baseUrl =
      ENV === "staging"
        ? "https://staging-chatgpt-academy-8b1b4.web.app/checkout"
        : "https://training.chatgpt-academy.fr/checkout";
    const checkoutDiscountUrl = `${baseUrl}/${randomId}`;

    if (checkoutDiscountLink) {
      setFormData({
        title: checkoutDiscountLink.title || "",
        price: +checkoutDiscountLink.price,
        checkoutDiscountUrl: checkoutDiscountLink.checkoutDiscountUrl || checkoutDiscountUrl,
        randomId: checkoutDiscountLink.randomId,
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        checkoutDiscountUrl,
        randomId,
      }));
    }
  }, []);
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.title) newErrors.title = "Title is required";
    if (!formData.price) newErrors.price = "Price is required";
    return Object.keys(newErrors).length === 0;
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      console.log("Form not validated...");
      return;
    }

    setIsLoading(true);
    try {
      const checkoutDiscountLinkData = {
        title: formData.title,
        price: +formData.price,
        checkoutDiscountUrl: formData.checkoutDiscountUrl,
        randomId: formData.randomId,
      };

      if (checkoutDiscountLink) {
        const res = await updateCheckoutDiscountLink(checkoutDiscountLink.id, checkoutDiscountLinkData);
        res.status === "success"
          ? toast.success(res.message, {
              position: "top-center",
            })
          : toast.error(res.message, {
              position: "top-center",
            });
      } else {
        
        const res = await addCheckoutDiscountLink(checkoutDiscountLinkData);
        res.status === "success"
          ? toast.success(res.message, {
              position: "top-center",
            })
          : toast.error(res.message, {
              position: "top-center",
            });
      }
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(error.message, {
        position: "top-center",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="p-6 bg-[#060B26] rounded-xl border-[#ffffff29] border text-white"
    >
      <h2 className="text-xl text-start font-medium py-4 border-b mb-6">
        {checkoutDiscountLink ? "Update CheckoutDiscountLink" : "Add CheckoutDiscountLink"}
      </h2>

      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="title" className="block text-sm font-medium mb-1">
          Title
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {errors.title && (
          <p className="mt-1 text-sm text-red-500">{errors.title}</p>
        )}
      </div>
      
      <div className="mb-4 flex-col items-start">
        <label
          htmlFor="value"
          className="block text-start text-sm font-medium mb-1"
        >
          Price
        </label>
        <input
          type="number"
          value={formData.price}
          id="price"
          name="price"
          price={formData.price}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        {errors.price && (
          <p className="mt-1 text-sm text-red-500">{errors.price}</p>
        )}
      </div>
      
      
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="checkoutDiscountUrl" className="block text-sm font-medium mb-1">
          Checkout Discount URL
        </label>
        <input
          type="text"
          id="checkoutDiscountUrl"
          name="checkoutDiscountUrl"
          value={formData.checkoutDiscountUrl}
          readOnly
          className="w-full p-2 text-start text-white bg-[#1C2536] h-full rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isLoading}
          className={`px-4 py-2 bg-primaryBlue text-white rounded ${
            isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-600"
          }`}
        >
          {isLoading
            ? "Processing..."
            : checkoutDiscountLink
            ? "Update Link"
            : "Add Link"}
        </button>
      </div>
    </form>
  );
};

export default CheckoutDiscountLinkForm;
