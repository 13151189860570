import React, { useState, useEffect } from "react";
import PriceCard from "../Components/PriceCard";
import Header from "../Components/Header";
import { collection, getDocs } from "firebase/firestore"; 
import { db } from "../firebase/config";

const ManagePrice = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const [priceCardData, setPriceCardData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchPriceData = async () => {
    try {
      const priceDocRef = collection(db, "coursePrice");
      const priceDocSnap = await getDocs(priceDocRef);

      if (priceDocSnap.docs[0].data()) {
        setPriceCardData({
          id: priceDocSnap.docs[0].id,
          ...priceDocSnap.docs[0].data(),
        }); 
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching price data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPriceData();
  }, []); // Fetch data only once when the component mounts

  return (
    <div className="flex flex-col h-[100%]">
      <div className="w-[100%]">
        <Header
          PageName={"Price"}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>
      <div className="w-full flex items-start bg-primary opacity-75 rounded-sm h-full border border-[#ffffff18]">
        <div className="container mx-auto p-4">
          {/* Show loading while fetching data */}
          {loading ? (
            <p className="text-white">Loading price data...</p>
          ) : (
            <div>
              <PriceCard priceData={priceCardData} fetchPriceData = {fetchPriceData} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagePrice;
