import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { useFirebase } from "../context/firebaseContext";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/outline";

import { db } from "../firebase/config";
import { collection, onSnapshot } from "firebase/firestore";
import Modal from "../Components/Modal";
import QuizForm from "../Components/QuizForm";
import toast from "react-hot-toast";
import { Search } from "lucide-react";

const Quizes = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const [quizes, setQuizes] = useState([]);
  const [filteredQuizes, setFilteredQuizes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedQuiz, setSelectedQuiz] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { deleteQuiz } = useFirebase();

  useEffect(() => {
    const quizCollection = collection(db, "quizes");
    const unsubscribe = onSnapshot(quizCollection, (snapshot) => {
      const quizList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setQuizes(quizList);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleAdd = () => {
    setSelectedQuiz(null);
    setIsModalOpen(true);
  };

  const handleUpdate = (quiz) => {
    setSelectedQuiz(quiz);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedQuiz(null);
  };

  useEffect(() => {
    const results = quizes.filter(
      (quiz) =>
        quiz.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        quiz.createdBy.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredQuizes(results);
  }, [searchTerm, quizes]);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this quiz?")) {
      await deleteQuiz(id);
      toast.success("Quizz deleted successfully...", {
        position: "top-center",
      });
    }
  };

  return (
    <div className="w-full rounded-sm h-full">
      <div className="flex flex-col">
        <Header
          PageName={"quizes"}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>
      <div className="rounded-sm border-[#ffffff18] border">
        <div className="w-full p-6 bg-primary opacity-75 rounded-sm ">
          <div className="flex justify-between items-center mb-4">
            <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              className="pl-10 pr-4 py-2 bg-white/5 border border-borderColor rounded-sm text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-primaryBlue/50"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
                      <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white/40 w-4 h-4" />

            </div>
            <button
              onClick={handleAdd}
              className="px-4 py-2 bg-gradient-to-r from-[#113be1] via-[#4268ff] to-[#113be1] text-white rounded-sm"
            >
              Add Quiz
            </button>
          </div>
          <div className="overflow-x-auto scrollbar-thin scrollbar-thumb-rounded-sm scrollbar-thumb-blue-500">
            <table className="min-w-full w-[1900px] divide-y divide-[#fefefe50]  ">
              <thead className="bg-[#1c253600]">
                <tr>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                    Title
                  </th>
                  <th className=" py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                    Des
                  </th>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                    Order
                  </th>

                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                    T.Marks
                  </th>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                    Difficulty
                  </th>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                    Level
                  </th>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                    Quiz No
                  </th>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                    Duration
                  </th>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                    Status
                  </th>
                  <th className=" py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                    Module
                  </th>
                  <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-primary opacity-75 divide-y divide-[#fefefe18]">
                {filteredQuizes.map((quiz) => (
                  <tr key={quiz.id}>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                      {quiz.title}
                    </td>
                    <td
                      title={quiz.description}
                      className="max-w-[300px] truncate px-2 text-left py-4 whitespace-nowrap text-sm text-white"
                    >
                      {quiz.description}
                    </td>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                      {quiz.order}
                    </td>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                      {quiz.totalMarks}
                    </td>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                      {quiz.difficulty}
                    </td>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                      {quiz.level}
                    </td>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                      {quiz?.quizNumber}
                    </td>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                      {quiz?.duration}
                    </td>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                      {quiz.status}
                    </td>
                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                      {quiz?.module.title}
                    </td>

                    <td className="text-left py-4 whitespace-nowrap text-sm font-medium">
                      <button
                        onClick={() => handleUpdate(quiz)}
                        className="text-indigo-600 hover:text-indigo-900 mr-2"
                      >
                        <PencilIcon className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(quiz.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Modal for Adding Quiz */}
        {isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            <QuizForm quiz={selectedQuiz} onClose={handleCloseModal} />
          </Modal>
        )}

      </div>
    </div>
  );
};

export default Quizes;
