import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";
import SignupBg from "../Images/SignupBg.png";
// import PayaplImg from "../Images/PayPal.png";
import logo from "../Images/LogoWithText.png";
import { ArrowLeft, Home, Loader2 } from "lucide-react";
// import PaymentOptions, {
//   PaymentButtonRadio,
// } from "../Components/PaymentOptions";
import { appearance } from "../data/signupData";
import StripeSkeleton from "../Skeleton/StripeSkeleton";
import BenefitsSkeleton from "../Skeleton/BenefitsSkeleton";
import { Steps } from "../Components/singup/Steps";
import { Benefits } from "../Components/singup/Benefits";
import PriceCard from "../Components/singup/PriceCard";
import { getBrowserInformation } from "../utils/getBrowserInfo";
import ExpiryTimer from "../Components/ExpiryTimer";
import { calculateTimeLeft } from "../utils/calculateTimeLeft";
// import StripeBuyButton from "../Components/StripeBuyButton";
// import { v4 as uuidv4 } from "uuid";

const ENV = process.env.REACT_APP_ENV;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
  locale: "fr",
});
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const UserInfoForm = ({
  onNext,
  register,
  errors,
  password,
  isValid,
  isLoading,
}) => (
  <form className="flex flex-col gap-4 mb-2 font-poppins">
    <div className="grid grid-cols-2 gap-4">
      <div>
        <label
          htmlFor="prénom"
          className="block text-start text-sm text-gray-300 mb-1"
        >
          Prénom
        </label>
        <input
          id="prénom"
          type="text"
          {...register("firstName", { required: "Le prénom est requis" })}
          className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
          placeholder="Votre prénom"
        />
        {errors.firstName && (
          <p className="text-red-500 text-sm mt-1">
            {errors.firstName.message}
          </p>
        )}
      </div>
      <div>
        <label
          htmlFor="nom"
          className="block text-start text-sm text-white mb-1"
        >
          Nom
        </label>
        <input
          id="nom"
          type="text"
          {...register("lastName", { required: "Le nom est requis" })}
          className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
          placeholder="Votre nom"
        />
        {errors.lastName && (
          <p className="text-red-500 text-sm mt-1">{errors.lastName.message}</p>
        )}
      </div>
    </div>
    <div>
      <label
        htmlFor="email"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Adresse mail
      </label>
      <input
        id="email"
        type="email"
        placeholder="email@domain.com"
        {...register("email", {
          required: "L'email est obligatoire",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Adresse email invalide",
          },
        })}
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
      {errors.email && (
        <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
      )}
    </div>
    <div>
      <label
        htmlFor="password"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Mot de passe
      </label>
      <input
        id="password"
        type="password"
        placeholder="******"
        {...register("password", {
          required: "Le mot de passe est obligatoire",
          minLength: {
            value: 6,
            message: "Le mot de passe doit comporter au moins 6 caractères",
          },
        })}
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
      {errors.password && (
        <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>
      )}
    </div>
    <div>
      <label
        htmlFor="confirmPassword"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Confirmer le mot de passe
      </label>
      <input
        id="confirmPassword"
        type="password"
        placeholder="******"
        {...register("confirmPassword", {
          required: "La confirmation du mot de passe est obligatoire",
          validate: (value) =>
            value === password || "Les mots de passe ne correspondent pas",
        })}
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
      {errors.confirmPassword && (
        <p className="text-red-500 text-sm mt-1">
          {errors.confirmPassword.message}
        </p>
      )}
    </div>
    <div>
      <label
        htmlFor="phone"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Numéro de téléphone (optionnel)
      </label>
      <input
        id="phone"
        type="tel"
        placeholder="+33 XX XX XX XX"
        {...register("phoneNumber")}
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
    </div>
    <button
      onClick={onNext}
      disabled={!isValid || isLoading}
      className={`
        w-full py-3 px-4 mt-4
        border border-transparent rounded-md 
        shadow-sm text-sm font-medium text-white 
        bg-gradient-to-r from-[#2952F0] via-[#4268FF] to-[#113BE1]
        bg-indigo-600 hover:bg-indigo-700 
        focus:outline-none focus:ring-4 focus:ring-indigo-500/50 
        transition-all duration-300
        ${isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-indigo-700"}
      `}
    >
      {isLoading ? (
        <div className="flex items-center  justify-center">
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Chargement...
        </div>
      ) : (
        "Enregistrez votre compte"
      )}
    </button>
  </form>
);

const PaymentComponent = ({
  userDetails,
  onPaymentSuccess,
  disabled,
  clientSecret,
  timeLeft,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handlePaymentAndSignup = async () => {
    if (!stripe || !elements) return;

    setProcessing(true);

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) throw new Error(submitError.message);

      if (window && ENV === "production") {
        window.lintrk("track", { conversion_id: 1895034 });
      }

      const paymentResult = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url:
            `${REACT_APP_BASE_URL}/payment-success`,
        },
        redirect: "if_required",
      });

      if (paymentResult.error) throw new Error(paymentResult.error.message);

      if (paymentResult.paymentIntent.status === "succeeded") {
        onPaymentSuccess(paymentResult.paymentIntent, clientSecret);
      }
    } catch (err) {
      setError(err.message);
      toast.error("Le paiement a échoué. Merci de réessayer.");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="relative">
      <PaymentElement />
      <button
        onClick={handlePaymentAndSignup}
        disabled={disabled || !stripe || processing || timeLeft?.expired}
        className="w-full mt-4 py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-[#2952F0] via-[#4268FF] to-[#113BE1] bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
      >
        {processing ? "Processing..." : "Finaliser le paiement"}
      </button>
      {error && <div className="text-red-500 mt-2">{error}</div>}
    </div>
  );
};

const Signup = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isComponentLoading, setIsComponentLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [priceData, setPriceData] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("stripe");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPrivateLinkExpired, setIsPrivateLinkExpired] = useState(false);
  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(priceData?.expiry)
  );
  // const uuid = uuidv4();
  // const [subscribeEmail, setSubscribeEmail] = useState("");
  const { id } = useParams();
  const browserInfo = getBrowserInformation();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors, isValid },
    watch,
    trigger,
  } = useForm({ mode: "onChange" });

  const watchedFields = watch();
  const password = watch("password");

  const createPaymentIntentWithUserData = async () => {
    setIsComponentLoading(true);
    try {
      if (isPrivateLinkExpired) {
        throw new Error("Le lien d'inscription à prix réduit a expiré");
      }
      const functions = getFunctions();
      const createPaymentIntent = httpsCallable(
        functions,
        "createPaymentIntent"
      );

      if (window && ENV === "production") {
        window.lintrk("track", { conversion_id: 1895034 });
      }

      const result = await createPaymentIntent({
        totalPrice: parseFloat(priceData.totalPrice),
        currency: "EUR",
        ...watchedFields,
        fullName: watchedFields.firstName + " " + watchedFields.lastName,
        checkoutDiscountLinkId: id,
        browserInfo,
      });

      if (result.data.error) throw new Error(result.data.error);

      setClientSecret(result.data.clientSecret);
      setIsComponentLoading(false);
      setLoading(false);
    } catch (err) {
      const error = String(err).split(":");
      const errMessage = error[error.length - 1].trim();

      if (errMessage === "Email already registered") {
        toast.error("Email déjà inscrit");
      } else if (
        errMessage === "Le lien d'inscription à prix réduit a expiré"
      ) {
        toast.error(errMessage);
      } else {
        toast.error(
          "Erreur lors de la mise à jour des informations de paiement. Veuillez réessayer."
        );
      }
      return false;
    }
    return true;
  };

  const handleNextStep = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const isValid = await trigger();

    if (isValid) {
      const pi = await createPaymentIntentWithUserData();
      if (pi) {
        setCurrentStep(2);
      }
    }
    setIsSubmitting(false);
  };

  const handlePrevStep = () => {
    setCurrentStep(1);
  };

  const checkDiscountedPriceLink = async (id) => {
    try {
      const privateLinkDiscount = await getDoc(doc(db, "signupLinks", id));
      if (privateLinkDiscount.exists) {
        const data = privateLinkDiscount.data();

        //check if not expired
        const currentTime = new Date();
        const expiry = new Date(data.expiry.toDate());

        if (currentTime < expiry) {
          setPriceData({
            id: privateLinkDiscount.id,
            ...data,
          });
          return { status: "success", data };
        } else {
          return { status: "expired" };
        }
      }
    } catch (error) {
      console.error("Error fetching private link data:", error);
    }
  };
  const fetchPriceData = async () => {
    try {
      const priceDocRef = collection(db, "coursePrice");
      const priceDocSnap = await getDocs(priceDocRef);

      if (priceDocSnap.docs[0].data()) {
        setPriceData({
          id: priceDocSnap.docs[0].id,
          ...priceDocSnap.docs[0].data(),
        });
      }
    } catch (error) {
      console.error("Error fetching price data:", error);
    }
  };

  // const handlePaypalPayment = async () => {
  //   setLoading(true);
  //   const userData = {
  //     firstName: watchedFields.firstName,
  //     lastName: watchedFields.lastName,
  //     email: watchedFields.email,
  //     password: password,
  //     phoneNumber: watchedFields.phoneNumber || "",
  //     fullName: `${watchedFields.firstName} ${watchedFields.lastName}`,
  //   };

  //   try {
  //     const functions = getFunctions();
  //     const createPaypalOrder = httpsCallable(functions, "createPaypalOrder");

  //     await createPaypalOrder({
  //       userData,
  //       currency: "EUR",
  //       amount: priceData.totalPrice,
  //       privateLinkId: id || "",
  //       browserInfo,
  //     }).then((res) => {
  //       setLoading(false);
  //       const url = res.data.url;
  //       window.open(url, "_self");
  //     });
  //   } catch (err) {
  //     toast.error(
  //       "Erreur lors de la mise à jour des informations de paiement. Veuillez réessayer."
  //     );
  //     setLoading(false);
  //   } finally {
  //   }
  // };

  useEffect(() => {
    if (id) {
      checkDiscountedPriceLink(id).then((res) => {
        if (res.status === "expired") {
          setIsPrivateLinkExpired(true);
          fetchPriceData();
        } else {
          setIsPrivateLinkExpired(false);
        }
        setLoading(false);
      });
    } else {
      fetchPriceData().then(() => {
        setLoading(false);
      });
    }
  }, [id]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(priceData?.expiry));
    }, 1000);

    return () => clearInterval(timer);
  }, [priceData?.expiry]);
  const handlePaymentSuccess = async (paymentIntent, client_secret) => {
    try {
      setTimeout(() => {
        navigate("/payment-success", {
          state: { client_secret, paymentIntent },
        });
      }, 1200);
    } catch (error) {
      toast.error("An error occurred after payment. Please contact support.");
    }
  };
  const loader = "auto";
  return (
    <div
      className="min-h-screen h-fit w-full flex flex-col bg-[#00061E] bg-cover bg-no-repeat justify-center items-center"
      style={{ backgroundImage: `url(${SignupBg})` }}
    >
      <div className="my-1 flex items-center gap-2">
        <img src={logo} width={140} height={130} alt="Logo" />
        {ENV === "staging" && (
          <div className="text-sm text-gray-500">{ENV}</div>
        )}
      </div>

      <div className="w-[90%] lg:w-[85%] py-4 ">
        <div className="flex flex-col items-start gap-10 min-w-[100%] flex-grow lg:flex-row md:gap-8 lg:gap-14">
          <div className="w-full lg:w-[54%] order-2 px-2 h-full">
            <Link
              to={"https://chatgpt-academy.fr/"}
              className="flex item-center justy-center  mb-3 text-[#ffffff]"
            >
              <ArrowLeft className="text-white" />{" "}
              <span className="">Revenir sur la page d'accueil</span>
            </Link>
            {currentStep === 2 && isComponentLoading ? (
              <StripeSkeleton />
            ) : (
              <div className="">
                {currentStep === 2 && (
                  <PriceCard
                    priceData={priceData}
                    selectedMethod={selectedPaymentMethod}
                  />
                )}
                {currentStep === 2 && priceData?.expiry && (
                  <div>
                    <h2
                      className={`text-lg text-start ${
                        timeLeft.expired ? "text-red-500" : "text-white"
                      } flex ms-4 gap-2 items-center`}
                    >
                      <div className="h-[14px] w-[14px] bg-[#ff1717] rounded-full animate-pulse-dot">
                        {" "}
                      </div>{" "}
                      {timeLeft.expired
                        ? "Offre expirée :"
                        : "L’offre expire dans :"}
                    </h2>
                    {<ExpiryTimer expiryDate={priceData.expiry} />}
                  </div>
                )}

                <div className="rounded-lg bg-[#ffffff12]  pt-4 h-fit form-card">
                  <Steps currentStep={currentStep} />

                  <div className="p-10 pt-0 pb-4">
                    {currentStep === 1 ? (
                      <>
                        <h1 className="text-start font-bold text-2xl mb-6 text-gray-300">
                          Informations personnelles
                        </h1>
                        <UserInfoForm
                          isValid={isValid}
                          onNext={handleNextStep}
                          register={register}
                          errors={errors}
                          password={password}
                          isLoading={isSubmitting}
                        />
                      </>
                    ) : (
                      <>
                        <div className="flex items-center gap-2 mb-6">
                          <button
                            onClick={handlePrevStep}
                            className="text-gray-300 hover:text-white flex items-center gap-2"
                          >
                            <ArrowLeft className="w-4 h-4" />
                          </button>
                          <h1 className="text-start font-bold text-2xl text-gray-300">
                            Informations de paiement
                          </h1>
                        </div>
                        <div>
                          {/* <h2 className="text-start text-gray-300 text-sm mb-4">
                            Type de paiement
                          </h2> */}
                          {/* <PaymentButtonRadio
                            onMethodChange={(method) => {
                              setSelectedPaymentMethod(method);
                            }}
                          /> */}

                          {selectedPaymentMethod === "stripe" && (
                            <>
                              {clientSecret ? (
                                <Elements
                                  stripe={stripePromise}
                                  options={{
                                    clientSecret,
                                    appearance,
                                    loader,
                                    fields: {
                                      billingDetails: {
                                        address: {
                                          country: "never",
                                        },
                                      },
                                    },
                                  }}
                                >
                                  <PaymentComponent
                                    userDetails={watchedFields}
                                    onPaymentSuccess={handlePaymentSuccess}
                                    disabled={!isValid || loading}
                                    clientSecret={clientSecret}
                                    timeLeft={timeLeft}
                                  />
                                </Elements>
                              ) : (
                                "Something went wrong while payment card loading..."
                              )}
                            </>
                          )}

                          {/* {selectedPaymentMethod === "paypal" && (
                            <div className="w-full rounded-lg text-white text-center "> */}
                              {/* <div className="flex flex-col gap-2">
                                <StripeBuyButton
                                  uuid={uuid}
                                  email={watchedFields.email}
                                  isUserData= {true}
                                  userData={{
                                    firstName: watchedFields.firstName,
                                    lastName: watchedFields.lastName,
                                    email: watchedFields.email,
                                    password: password,
                                    phoneNumber:
                                      watchedFields.phoneNumber || "",
                                    displayName: `${watchedFields.firstName} ${watchedFields.lastName}`,
                                  }}
                                />
                              </div> */}
                              {/* <div className="my-4 font-bold flex items-center">
                                <div className="w-full h-[1px] bg-[#bababa]"></div>
                                <div className="mx-4">OU</div>
                                <div className="w-full h-[1px] bg-[#bababa]"></div>
                              </div> */}
                              {/* <button
                                onClick={() => handlePaypalPayment()}
                                disabled={loading}
                                className="flex items-center w-full h-[55px] disabled:opacity-50 disabled:cursor-not-allowed py-4 mb-2 rounded-lg bg-[#FFD401] justify-center text-center gap-2 cursor-pointer"
                              >
                                {loading ? (
                                  <div className="flex justify-center items-center">
                                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-[#003087]"></div>
                                  </div>
                                ) : (
                                  <img
                                    src={PayaplImg}
                                    className="h-[30px] w-[150px]"
                                    alt="paypal-button"
                                  />
                                )}
                              </button>
                            </div> */}
                          {/* )} */}

                          {/* {selectedPaymentMethod === "applepay" && (
                            <div className="w-full p-4 bg-[#FFFFFF1A] rounded-lg text-white text-center">
                              Apple Pay
                            </div>
                          )} */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}

            <p className="mt-2 text-center text-sm text-gray-600">
              Déjà inscrit?{" "}
              <Link
                to="/login"
                className="font-medium text-[#284bff] hover:text-indigo-500"
              >
                Connexion
              </Link>
            </p>
          </div>

          <div className="w-full lg:w-[64%] flex items-start justify-start flex-col order-1">
            {currentStep === 2 && isComponentLoading ? (
              <BenefitsSkeleton />
            ) : (
              <Benefits
                discount={parseFloat(priceData?.discountPercentage).toFixed(0)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
