import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import StripeCheckoutSkeleton from "../Skeleton/StripeCheckoutSkeletion";
import { checkoutAppearance } from "../data/signupData";

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const CheckoutForm = ({ email, uuid, isUserData, userData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const return_url = isUserData
      ? `${REACT_APP_BASE_URL}/payment-success`
      : `${REACT_APP_BASE_URL}/checkout/subscription-success?id=${uuid}`;
    console.log("Return Url: ", return_url);

    setIsLoading(true);
    setErrorMessage(null);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: return_url,
        },
      });

      if (error) {
        setErrorMessage(error.message);
        toast.error(error.message);
      }
    } catch (err) {
      setErrorMessage(err.message);
      toast.error("Payment failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full space-y-4">
      <PaymentElement />
      <button
        type="submit"
        disabled={!stripe || isLoading}
        className={`
          w-full  rounded-md text-center
          transition-all duration-200  mt-2 py-3 px-2 border border-transparent  shadow-sm text-sm font-medium text-white bg-gradient-to-r from-[#2952F0] via-[#4268FF] to-[#113BE1] bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50
        `}
      >
        {isLoading ? "Processing..." : "Finaliser le paiement"}
      </button>
      {errorMessage && (
        <div className="mt-4 p-3 bg-red-50 text-red-700 rounded-md text-sm">
          {errorMessage}
        </div>
      )}
    </form>
  );
};

// Main component
const StripeBuyButton = ({ uuid, email, isUserData, userData }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState(null);
  console.log("User Data: ", isUserData);

  useEffect(() => {
    setStripePromise(loadStripe(STRIPE_PUBLIC_KEY));
  }, []);

  useEffect(() => {
    const initializePaymentIntent = async () => {
      if (!email) {
        return;
      }

      try {
        const functions = getFunctions();
        const createSubscriptionIntent = httpsCallable(
          functions,
          "createSubscriptionIntent"
        );

        const response = await createSubscriptionIntent({
          email,
          id: uuid,
          path: "/subscription",
          isUserData,
          userData
        });
        setClientSecret(response.data.clientSecret);
      } catch (err) {
        setError(err.message);
        toast.error("Failed to initialize payment. Please try again.");
      }
    };

    initializePaymentIntent();
  }, []);

  if (!clientSecret) {
    return (
      <div>
        <StripeCheckoutSkeleton />
      </div>
    );
  }

  return (
    <div className="w-full mx-auto my-4">
      {stripePromise && clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
            loader: "auto",
            appearance: checkoutAppearance,
          }}
        >
          <CheckoutForm email={email} uuid={uuid} isUserData={isUserData} userData={userData}/>
        </Elements>
      )}
      {error && (
        <div className="mt-4 p-3 bg-red-50 text-red-700 rounded-md text-sm">
          {error}
        </div>
      )}
    </div>
  );
};

export default StripeBuyButton;
