import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";
import SignupBg from "../Images/SignupBg.png";
import logo from "../Images/LogoWithText.png";
import { ArrowLeft, Home, Loader2 } from "lucide-react";
import { appearance, checkoutAppearance } from "../data/signupData";
import { getBrowserInformation } from "../utils/getBrowserInfo";
import StripeCheckoutSkeleton from "../Skeleton/StripeCheckoutSkeletion";
import PriceHeaderSkeleton from "../Skeleton/PriceHeaderSkeleton";

const ENV = process.env.REACT_APP_ENV;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
  locale: "fr",
});

const PaymentComponent = ({
  userDetails,
  email,
  onPaymentSuccess,
  disabled,
  clientSecret,
  timeLeft,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [updatedClientSecret, setUpdatedClientSecret] = useState("");

  const updateCheckoutPaymentIntent = async (newEmail) => {
    if (!clientSecret) return;

    try {
      const functions = getFunctions();
      const updateIntent = httpsCallable(
        functions,
        "updateCheckoutPaymentIntent"
      );

      const result = await updateIntent({
        email: newEmail,
        paymentIntentId: clientSecret.split("_secret")[0],
        isTrail: "true",
        isUser: "false",
      });

      if (result.data.error) throw new Error(result.data.error);
      setUpdatedClientSecret(result.data.clientSecret);
      return result.data.clientSecret;

    } catch (err) {
      console.log("Error: ", err)
      const error = String(err).split(":");
      const errMessage = error[error.length - 1].trim();

      if (errMessage === "Email already registered") {
        toast.error("Email déjà inscrit");
      } else {
        toast.error(
          "Erreur lors de la mise à jour du client secret. Veuillez réessayer."
        );
      }
    }
  };

  const handlePaymentAndSignup = async () => {
    if (!stripe || !elements) return;
    setProcessing(true);

    const updatedCSecrete = await updateCheckoutPaymentIntent(email);

    if (updatedCSecrete) {
      try {
        const { error: submitError } = await elements.submit();
        if (submitError) throw new Error(submitError.message);

        if (window && ENV === "production") {
          window.lintrk("track", { conversion_id: 1895034 });
        }

        const paymentResult = await stripe.confirmPayment({
          elements,
          updatedClientSecret,
          confirmParams: {
            return_url: "https://training.chatgpt-academy.fr/payment-success",
          },
          redirect: "if_required",
        });

        if (paymentResult.error) throw new Error(paymentResult.error.message);

        if (paymentResult.paymentIntent.status === "succeeded") {
          onPaymentSuccess(paymentResult.paymentIntent, clientSecret);
        }
      } catch (err) {
        setError(err.message);
        toast.error("Le paiement a échoué. Merci de réessayer.");
      } finally {
        setProcessing(false);
      }
    } else{
      setProcessing(false);
    }
  };

  return (
    <div className="relative">
      <PaymentElement />
      <button
        onClick={handlePaymentAndSignup}
        disabled={disabled || !stripe || processing || timeLeft?.expired}
        className="w-full mt-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-[#2952F0] via-[#4268FF] to-[#113BE1] bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
      >
        {processing ? "Processing..." : "Finaliser le paiement"}
      </button>
      {error && <div className="text-red-500 mt-2">{error}</div>}
    </div>
  );
};

const CheckoutFifty = () => {
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [email, setEmail] = useState("");
  const { id } = useParams();
  const browserInfo = getBrowserInformation();
  const navigate = useNavigate();

  const createCheckoutPaymentIntent = async (price) => {
    try {
      const functions = getFunctions();
      const createCheckoutIntent = httpsCallable(
        functions,
        "createCheckoutPaymentIntent"
      );

      const result = await createCheckoutIntent({
        totalPrice: parseFloat(price),
        isCheckoutJoin: true,
        currency: "EUR",
        browserInfo,
      });

      if (result.data.error) throw new Error(result.data.error);

      setClientSecret(result.data.clientSecret);
      setLoading(false);
    } catch (err) {
      toast.error(
        "Erreur lors de l'initialisation du paiement. Veuillez réessayer."
      );
      setLoading(false);
      return false;
    }
    return true;
  };

  useEffect(() => {
    createCheckoutPaymentIntent(100);
  }, []);

  const handlePaymentSuccess = async (paymentIntent, client_secret) => {
    try {
      toast.success("Paiement réussi! Veuillez compléter votre inscription.");
      navigate("/checkoutFifty/payment-success");
    } catch (error) {
      toast.error(
        "Une erreur s'est produite après le paiement. Veuillez contacter le support."
      );
    }
  };

  const loader = "auto";

  return (
    <div
      className="min-h-screen h-fit w-full flex flex-col bg-[#00061E] bg-cover bg-no-repeat pt-10 items-center"
      style={{ backgroundImage: `url(${SignupBg})` }}
    >
      <div className="my-1 flex items-center gap-2">
        <img src={logo} width={140} height={130} alt="Logo" />
        {ENV === "staging" && (
          <div className="text-sm text-gray-500">{ENV}</div>
        )}
      </div>

      <div className="w-[90%] lg:w-[85%] py-4">
        <div className="flex flex-col items-center gap-10 min-w-[100%]">
          <div className="w-full md:w-[75%] lg:w-[45%] px-2 h-full">
            <div className="">
              <div className="rounded-lg bg-[#ffffff12] pt-4 form-card">
                <div className="p-3 md:p-10 pt-4 md:pt-3">
                  {clientSecret ? (
                    <>
                      <div className="text-start bg-slate-800 p-4 rounded-lg font-bold text-xs md:text-sm text-gray-300 mb-3 flex justify-between">
                        <div>Acompte versé à la ChatGPT Academy </div>
                        <div className="w-[70px]">100.00 €</div>
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="email"
                          className="block text-start text-sm text-gray-300 mb-1"
                        >
                          Adresse mail
                        </label>
                        <input
                          onChange={(e) => setEmail(e.target.value)}
                          id="email"
                          type="email"
                          value={email}
                          required
                          placeholder="email@domain.com"
                          className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
                        />
                      </div>
                    </>
                  ) : (
                    <PriceHeaderSkeleton />
                  )}
                  <div>
                    {clientSecret ? (
                      <Elements
                        stripe={stripePromise}
                        options={{
                          clientSecret,
                          appearance: checkoutAppearance,
                          loader,
                          fields: {
                            billingDetails: {
                              address: {
                                country: "never",
                              },
                            },
                          },
                        }}
                      >
                        <PaymentComponent
                          onPaymentSuccess={handlePaymentSuccess}
                          clientSecret={clientSecret}
                          email={email}
                          loading={loading}
                        />
                      </Elements>
                    ) : (
                      <StripeCheckoutSkeleton />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutFifty;
