import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { useFirebase } from "../context/firebaseContext";
import { TrashIcon, PencilIcon } from '@heroicons/react/24/outline';

import { db } from "../firebase/config";
import { collection, onSnapshot } from "firebase/firestore";
import Modal from "../Components/Modal";
import SectionForm from "../Components/SectionForm";
import toast from "react-hot-toast";
import { Search } from "lucide-react";

const Sections = ({ isSidebarOpen, setIsSidebarOpen }) => {

  const [sections, setSections] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSection, setSelectedSection] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { deleteSection } = useFirebase();


  useEffect(() => {
    const videoCollection = collection(db, "sections");
    const unsubscribe = onSnapshot(videoCollection, (snapshot) => {
      const sectionList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSections(sectionList);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);


  const handleAdd = () => {
    setSelectedSection(null);
    setIsModalOpen(true);
  };

  const handleUpdate = (section) => {
    setSelectedSection(section);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSection(null);
  };





  useEffect(() => {
    const results = sections.filter(section =>
      section.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      section.createdBy.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSections(results);
  }, [searchTerm, sections]);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this section?')) {
      await deleteSection(id)
      toast.success("Section deleted successfully...", {
        position: 'top-center'
      })
    }
  };

  return (
    <div className="w-full rounded-sm h-full">
      <div className="flex flex-col">
        <Header
          PageName={"sections"}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>
      <div className="">
        <div className="w-full p-6 bg-primary opacity-75 rounded-sm">

          <div className="flex justify-between items-center mb-4">
            <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              className="pl-10 pr-4 py-2 bg-white/5 border border-borderColor rounded-sm text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-primaryBlue/50"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white/40 w-4 h-4" />

            </div>
            <button
              onClick={handleAdd}
              className="px-4 py-2 bg-gradient-to-r from-[#113be1] via-[#4268ff] to-[#113be1] text-white rounded-sm"
            >
              Add Section
            </button>
          </div>
          <table className="min-w-full divide-y divide-[#fefefe50]">
            <thead className="bg-[#1c253600]">
              <tr>
                <th className=" py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Title</th>
                <th className=" py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Description</th>
                <th className=" py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Level</th>
                <th className=" py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Created By</th>
                <th className=" py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Status</th>
               
                <th className=" py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-[#060B26] divide-y divide-[#fefefe20]">
              {filteredSections.map((section) => (
                <tr key={section.id}>
                  <td className="text-left py-4 whitespace-nowrap text-sm text-white">{section.title}</td>
                  <td className="text-left py-4 whitespace-nowrap text-sm text-white">{section.description}</td>
                  <td className="text-left py-4 whitespace-nowrap text-sm text-white">{section.levelName}</td>
                  <td className="text-left py-4 whitespace-nowrap text-sm text-white">{section.createdBy}</td>
                  <td className="text-left py-4 whitespace-nowrap text-sm text-white">{section.status}</td>
                  <td className="text-left py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => handleUpdate(section)}
                      className="text-indigo-600 hover:text-indigo-900 mr-2"
                    >
                      <PencilIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(section.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Modal for Adding Video */}
        {isModalOpen && <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <SectionForm
            section={selectedSection}
            onClose={handleCloseModal}
          />
        </Modal>}
      </div>
    </div>
  );
};

export default Sections;
