import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { getFunctions, httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";
import SignupBg from "../Images/SignupBg.png";
import logo from "../Images/LogoWithText.png";
import { ArrowLeft, Loader2 } from "lucide-react";

import StripeSkeleton from "../Skeleton/StripeSkeleton";
import { Steps } from "../Components/singup/Steps";
import PriceCard from "../Components/singup/PriceCard";
import { getBrowserInformation } from "../utils/getBrowserInfo";
import { calculateTimeLeft } from "../utils/calculateTimeLeft";
import StripeBuyButton from "../Components/StripeBuyButton";
import { v4 as uuidv4 } from "uuid";
const ENV = process.env.REACT_APP_ENV;
const UserInfoForm = ({
  onNext,
  register,
  errors,
  password,
  isValid,
  isLoading,
}) => (
  <form className="flex flex-col gap-4 mb-2 font-poppins">
    <div className="grid grid-cols-2 gap-4">
      <div>
        <label
          htmlFor="prénom"
          className="block text-start text-sm text-gray-300 mb-1"
        >
          Prénom
        </label>
        <input
          id="prénom"
          type="text"
          {...register("firstName", { required: "Le prénom est requis" })}
          className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
          placeholder="Votre prénom"
        />
        {errors.firstName && (
          <p className="text-red-500 text-sm mt-1">
            {errors.firstName.message}
          </p>
        )}
      </div>
      <div>
        <label
          htmlFor="nom"
          className="block text-start text-sm text-white mb-1"
        >
          Nom
        </label>
        <input
          id="nom"
          type="text"
          {...register("lastName", { required: "Le nom est requis" })}
          className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
          placeholder="Votre nom"
        />
        {errors.lastName && (
          <p className="text-red-500 text-sm mt-1">{errors.lastName.message}</p>
        )}
      </div>
    </div>
    <div>
      <label
        htmlFor="email"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Adresse mail
      </label>
      <input
        id="email"
        type="email"
        placeholder="email@domain.com"
        {...register("email", {
          required: "L'email est obligatoire",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Adresse email invalide",
          },
        })}
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
      {errors.email && (
        <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
      )}
    </div>
    <div>
      <label
        htmlFor="password"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Mot de passe
      </label>
      <input
        id="password"
        type="password"
        placeholder="******"
        {...register("password", {
          required: "Le mot de passe est obligatoire",
          minLength: {
            value: 6,
            message: "Le mot de passe doit comporter au moins 6 caractères",
          },
        })}
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
      {errors.password && (
        <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>
      )}
    </div>
    <div>
      <label
        htmlFor="confirmPassword"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Confirmer le mot de passe
      </label>
      <input
        id="confirmPassword"
        type="password"
        placeholder="******"
        {...register("confirmPassword", {
          required: "La confirmation du mot de passe est obligatoire",
          validate: (value) =>
            value === password || "Les mots de passe ne correspondent pas",
        })}
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
      {errors.confirmPassword && (
        <p className="text-red-500 text-sm mt-1">
          {errors.confirmPassword.message}
        </p>
      )}
    </div>
    <div>
      <label
        htmlFor="phone"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Numéro de téléphone (optionnel)
      </label>
      <input
        id="phone"
        type="tel"
        placeholder="+33 XX XX XX XX"
        {...register("phoneNumber")}
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
    </div>
    <button
      onClick={onNext}
      disabled={!isValid || isLoading}
      className={`
        w-full py-3 px-4 mt-4
        border border-transparent rounded-md 
        shadow-sm text-sm font-medium text-white 
        bg-gradient-to-r from-[#2952F0] via-[#4268FF] to-[#113BE1]
        bg-indigo-600 hover:bg-indigo-700 
        focus:outline-none focus:ring-4 focus:ring-indigo-500/50 
        transition-all duration-300
        ${isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-indigo-700"}
      `}
    >
      {isLoading ? (
        <div className="flex items-center  justify-center">
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Chargement...
        </div>
      ) : (
        "Enregistrez votre compte"
      )}
    </button>
  </form>
);


const Subscription = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isComponentLoading, setIsComponentLoading] = useState(false);
  const [priceData, setPriceData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
 
  const uuid = uuidv4();
  const { id } = useParams();
  const browserInfo = getBrowserInformation();

  const {
    register,
    formState: { errors, isValid },
    watch,
    trigger,
  } = useForm({ mode: "onChange" });

  const watchedFields = watch();
  const password = watch("password");


  const handleNextStep = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const isValid = await trigger();

    if (isValid) {
        setCurrentStep(2);
    }
    setIsSubmitting(false);
  };

  const handlePrevStep = () => {
    setCurrentStep(1);
  };



  return (
    <div
      className="min-h-screen h-fit w-full flex flex-col  bg-[#00061E] bg-cover bg-no-repeat justify-center items-center"
      style={{ backgroundImage: `url(${SignupBg})` }}
    >
      <div className="my-1 flex items-center gap-2">
        <img src={logo} width={140} height={130} alt="Logo" />
        {ENV === "staging" && (
          <div className="text-sm text-gray-500">{ENV}</div>
        )}
      </div>

      <div className="w-[90%] py-4 flex justify-center">
          <div className="w-full lg:w-[50%]  px-2 h-full">
           
            {currentStep === 2 && isComponentLoading ? (
              <StripeSkeleton />
            ) : (
              <div className="">
                {currentStep === 2 && (
                  <PriceCard
                    priceData={priceData}
                    selectedMethod={"paypal"}
                  />
                )}

                <div className="rounded-lg bg-[#ffffff12]  pt-4 h-fit form-card">
                  <Steps currentStep={currentStep} />

                  <div className="p-10 pt-0 pb-4">
                    {currentStep === 1 ? (
                      <>
                        <h1 className="text-start font-bold text-2xl mb-6 text-gray-300">
                          Informations personnelles
                        </h1>
                        <UserInfoForm
                          isValid={isValid}
                          onNext={handleNextStep}
                          register={register}
                          errors={errors}
                          password={password}
                          isLoading={isSubmitting}
                        />
                      </>
                    ) : (
                      <>
                        <div className="flex items-center gap-2 mb-6">
                          <button
                            onClick={handlePrevStep}
                            className="text-gray-300 hover:text-white flex items-center gap-2"
                          >
                            <ArrowLeft className="w-4 h-4" />
                          </button>
                          <h1 className="text-start font-bold text-2xl text-gray-300">
                            Informations de paiement
                          </h1>
                        </div>
                        <div>
                          <h2 className="text-start text-gray-300 text-sm mb-4">
                            Type de paiement
                          </h2>
                            <div className="w-full rounded-lg text-white text-center ">
                              <div className="flex flex-col gap-2">
                                <StripeBuyButton
                                  uuid={uuid}
                                  email={watchedFields.email}
                                  isUserData= {true}
                                  userData={{
                                    firstName: watchedFields.firstName,
                                    lastName: watchedFields.lastName,
                                    email: watchedFields.email,
                                    password: password,
                                    phoneNumber:
                                      watchedFields.phoneNumber || "",
                                    displayName: `${watchedFields.firstName} ${watchedFields.lastName}`,
                                  }}
                                />
                              </div> 
                            </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/*  */}
          </div>
      </div>
    </div>
  );
};

export default Subscription;
