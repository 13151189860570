import CheckoutDiscountLink from "../pages/CheckoutDiscountLink";
import DepositsTracking from "../pages/Deposits";
import PaypalDiscountLink from "../pages/PayaplDiscountLink";
import UsersTracking from "../pages/UsersTracking";

const SalesRoutes = [
    {
      path: "/sales/user-tracking",
      exact: true,
      element: UsersTracking,
    },
    {
      path: "/sales/deposits",
      exact: true,
      element: DepositsTracking,
    },
    {
      path: "/sales/checkout-discount-links",
      exact: true,
      element: CheckoutDiscountLink,
    },
    {
      path: "/sales/paypal-discount-links",
      exact: true,
      element: PaypalDiscountLink,
    },
  
  ]

  export default SalesRoutes;