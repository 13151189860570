
import Rewards from "../pages/Rewards";
import Home from "../pages/Home";
import Videos from "../pages/Videos";
import Sections from "../pages/Sections";
import Quizes from "../pages/Quizes";
import Modules from "../pages/Modules";
import ManagePrice from "../pages/ManagePrice";
import AllQuestions from "../pages/AllQuestions";
import UserStats from "../Components/UsersStats";
import FinanceInsights from "../Components/FinancePage";
import UsersTracking from "../pages/UsersTracking";
import ManageUsers from "../pages/ManageUsers";
import DepositsTracking from "../pages/Deposits";
import PaypalDiscountLink from "../pages/PayaplDiscountLink";
import SourceAcquisition from "../pages/SourceAquisition";
import SalesByCommercial from "../pages/SalesByCommercials";
import Coupons from "../pages/Coupons";
import CheckoutDiscountLink from "../pages/CheckoutDiscountLink";


const AdminRoutes = [
  {
    path: "/admin/home",
    exact: true,
    element: Home,
  },
  {
    path: "/admin/videos",
    exact: true,
    element: Videos,
  },
  {
    path: "/admin/sections",
    exact: true,
    element: Sections,
  },

  {
    path: "/admin/rewards",
    exact: true,
    element: Rewards,
  },
  {
    path: "/admin/allquestions",
    exact: true,
    element: AllQuestions,
  },
  {
    path: "/admin/quizes",
    exact: true,
    element: Quizes,
  },
  {
    path: "/admin/modules",
    exact: true,
    element: Modules,
  },
  {
    path: "/admin/manage-price",
    exact: true,
    element: ManagePrice,
  },
  {
    path: "/admin/user-stats",
    exact: true,
    element: UserStats,
  },
  {
    path: "/admin/finance",
    exact: true,
    element: FinanceInsights,
  },
  {
    path: "/admin/user-tracking",
    exact: true,
    element: UsersTracking,
  },
  // {
  //   path: "/admin/coupons",
  //   exact: true,
  //   element: Coupons,
  // },
  {
    path: "/admin/checkout-discount-links",
    exact: true,
    element: CheckoutDiscountLink,
  },
  {
    path: "/admin/paypal-discount-links",
    exact: true,
    element: PaypalDiscountLink,
  },
  {
    path: "/admin/manage-users",
    exact: true,
    element: ManageUsers,
  },
  {
    path: "/admin/deposits",
    exact: true,
    element: DepositsTracking,
  },
  {
    path: "/admin/source-aquisition",
    exact: true,
    element: SourceAcquisition,
  },
  {
    path: "/admin/sales-by-commercials",
    exact: true,
    element: SalesByCommercial,
  },
];

export default AdminRoutes;
