import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/outline";
import Modal from "../Components/Modal";
import RewardForm from "../Components/RewardForm";
import { useFirebase } from "../context/firebaseContext";
import { db } from "../firebase/config";
import { collection, onSnapshot } from "firebase/firestore";
import toast from "react-hot-toast";
import { Search } from "lucide-react";


const Rewards = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const [filteredRewards, setFilteredRewards] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState([]);

  const {deleteReward } = useFirebase();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "rewards"),
      (snapshot) => {
        const rewardsArray = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setRewards(rewardsArray);
      },
      (error) => {
        console.error("Error getting rewards data:", error);
      }
    );

    // Clean up listener on component unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const results = rewards.filter(reward =>
      reward.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      reward.type.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRewards(results);
  }, [searchTerm, rewards]);

  const handleDelete = async(id) => {
      if(window.confirm('Are you sure you want to delete this reward?'))
      await deleteReward("rewards",id)
      toast.success("Reward deleted successfully...", {
        position: 'top-center'
      })

  };

  const handleUpdate = (reward) => {
    setSelectedReward(reward);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedReward(null)
  };
  const handleAdd = () => {
    setIsModalOpen(true);
    setSelectedReward(null);
  };


  return (
    <div className="w-full h-full rounded-sm">
      <div className="flex flex-col">
        <Header
          PageName={"rewards"}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>
      <div className="p-6 bg-primary opacity-75 rounded-sm border-[#ffffff18] border">
        <div className="flex justify-between items-center mb-4">
          <div className="relative">

          <input
            type="text"
            placeholder="Search..."
            className="pl-10 pr-4 py-2 bg-white/5 border border-borderColor rounded-sm text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-primaryBlue/50"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white/40 w-4 h-4" />

          </div>
          <button
            onClick={handleAdd}
            className="px-4 py-2 bg-gradient-to-r from-[#113be1] via-[#4268ff] to-[#113be1] text-white rounded-sm"
          >
            Add Reward
          </button>
        </div>
        <table className="min-w-full divide-y divide-[#fefefe50]">
          <thead className="bg-[#1c253600]">
            <tr>
              <th className=" py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                Name
              </th>
              <th className=" py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                File Type
              </th>
              <th className=" py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                UnlockAfter
              </th>
                <th className=" py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-primary divide-y divide-[#fefefe18]">
            {filteredRewards.map((reward) => (
              <tr key={reward.id}>
                   <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                  {reward.name}
                </td>
                <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                  {reward.type}
                </td>
                <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                  {reward.unlockAfter?.title}
                </td>
                  <td className="text-left py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    onClick={() => handleUpdate(reward)}
                    className="text-indigo-600 hover:text-indigo-900 mr-2"
                  >
                    <PencilIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleDelete(reward.id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isModalOpen && (
          <Modal isModalOpen={isModalOpen} onClose = {handleCloseModal}>
            <RewardForm reward={selectedReward} onClose={handleCloseModal} />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Rewards;
