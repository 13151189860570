import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase/config";
import "react-quill/dist/quill.snow.css";
import toast from "react-hot-toast";
import Header from "../Components/Header";

// Stat Card Component with themed colors
const StatCard = ({
  icon: Icon,
  title,
  value,
  iconColor = "bg-primaryBlue/20",
  textColor = "text-primaryBlue",
}) => (
  <div className="bg-white/10 backdrop-blur-sm rounded-sm border border-[#fefefe20] p-4 hover:shadow-lg transition-shadow duration-200">
    <div className="flex items-center space-x-4">
      <div className={`p-3 rounded-full ${iconColor}`}>
        <Icon className={`w-6 h-6 ${textColor}`} />
      </div>
      <div>
        <p className="text-sm font-medium text-white/60">{title}</p>
        <h3 className="text-2xl text-left font-bold text-white">{value}</h3>
      </div>
    </div>
  </div>
);

// Progress Bar Component
const ProgressBar = ({ percentage, label, color = "bg-blue-500" }) => (
  <div className="mb-4">
    <div className="flex justify-between mb-1">
      <span className="text-sm font-medium text-white">{label}</span>
      <span className="text-sm font-medium text-white">{percentage}%</span>
    </div>
    <div className="w-full bg-white/10 rounded-full h-2.5">
      <div
        className={`h-2.5 rounded-full ${color}`}
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  </div>
);

// Merged Dashboard Component
const SourceAcquisition = ({ setIsSidebarOpen, isSidebarOpen }) => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalDeals: 0,
    wonDeals: 0,
    organicDeals: 0,
    adsDeals: 0,
    totalRevenue: 0,
  });
  const [acquisitionSources, setAcquisitionSources] = useState([]);
  const [salesByCommercial, setSalesByCommercial] = useState([]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        
        // Create a query to get all sales deals
        const salesDealsRef = collection(db, "salesDeals");
        const salesDealsQuery = query(salesDealsRef);
        
        // Set up a real-time listener for the sales deals collection
        const unsubscribe = onSnapshot(salesDealsQuery, (querySnapshot) => {
          const deals = [];
          let totalRevenue = 0;
          let organicCount = 0;
          let adsCount = 0;
          let wonCount = 0;
          const dealsByOwner = {};
          const sourceMap = {};
          
          // Process each document
          querySnapshot.forEach((doc) => {
            const dealData = doc.data();
            deals.push(dealData);
            
            // Calculate stats for source acquisition
            if (dealData.status === "won") {
              wonCount++;
              totalRevenue += parseFloat(dealData.amountSpent || 0);
            }
            
            if (dealData.is_organic === "true") {
              organicCount++;
            }
            
            if (dealData.is_ads === "true") {
              adsCount++;
            }
            
            // Track acquisition sources
            if (dealData.source) {
              sourceMap[dealData.source] = (sourceMap[dealData.source] || 0) + 1;
            }
            
            // Process data for sales by commercial
            if (dealData.deal_owner_name) {
              if (!dealsByOwner[dealData.deal_owner_name]) {
                dealsByOwner[dealData.deal_owner_name] = {
                  name: dealData.deal_owner_name,
                  email: dealData.deal_owner_email || "",
                  id: dealData.deal_owner_id || "",
                  sales: 0,
                  amount: 0,
                  wonDeals: 0,
                };
              }
              
              dealsByOwner[dealData.deal_owner_name].sales += 1;
              
              if (dealData.status === "won" && dealData.amountSpent) {
                const amount = parseFloat(dealData.amountSpent) || 0;
                dealsByOwner[dealData.deal_owner_name].amount += amount;
                dealsByOwner[dealData.deal_owner_name].wonDeals += 1;
              }
            }
          });
          
          // Update stats
          setStats({
            totalDeals: deals.length,
            wonDeals: wonCount,
            organicDeals: organicCount,
            adsDeals: adsCount,
            totalRevenue: totalRevenue,
          });
          
          // Calculate acquisition sources and percentages
          const sourcesArray = Object.keys(sourceMap).map((source) => {
            const percentage = Math.round((sourceMap[source] / deals.length) * 100);
            return {
              source,
              percentage,
              color: "bg-blue-500",
            };
          });
          
          // Sort sources by percentage (descending)
          sourcesArray.sort((a, b) => b.percentage - a.percentage);
          setAcquisitionSources(sourcesArray);
          
          // Sort sales by commercial data
          const sortedSalesData = Object.values(dealsByOwner)
            .sort((a, b) => b.amount - a.amount);
          setSalesByCommercial(sortedSalesData);
          
          setLoading(false);
        });
        
        // Clean up the listener when the component unmounts
        return () => unsubscribe();
        
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to load dashboard data");
        setLoading(false);
      }
    };
    
    fetchDashboardData();
  }, []);

  // Assign different colors to the top sources
  const colorClasses = [
    "bg-blue-500",
    "bg-green-500",
    "bg-purple-500",
    "bg-orange-500",
    "bg-yellow-500",
    "bg-cyan-500",
  ];

  return (
    <div className="rounded-sm font-poppins">
      <div className="w-[100%]">
        <Header PageName={"dashboard"} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      </div>
      <div className="mx-auto space-y-6">
        {loading ? (
          <div className="rounded-sm border-[#ffffff55] border h-[90vh] flex justify-center items-center bg-primary opacity-75 font-poppins">
            <div className="animate-spin w-16 h-16 border-4 border-primaryBlue border-t-transparent rounded-full" />
          </div>
        ) : (
          <>
            {/* Stats Grid */}
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
              <StatCard
                icon={(props) => (
                  <svg
                    {...props}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                    />
                  </svg>
                )}
                title="Total Deals"
                value={stats.totalDeals}
                iconColor="bg-blue-500/20"
                textColor="text-blue-500"
              />
              <StatCard
                icon={(props) => (
                  <svg
                    {...props}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}
                title="Won Deals"
                value={stats.wonDeals}
                iconColor="bg-green-500/20"
                textColor="text-green-500"
              />
              <StatCard
                icon={(props) => (
                  <svg
                    {...props}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}
                title="Total Revenue"
                value={`$${stats.totalRevenue.toLocaleString()}`}
                iconColor="bg-purple-500/20"
                textColor="text-purple-500"
              />
              <StatCard
                icon={(props) => (
                  <svg
                    {...props}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                    />
                  </svg>
                )}
                title="Organic Deals"
                value={stats.organicDeals}
                iconColor="bg-cyan-500/20"
                textColor="text-cyan-500"
              />
              <StatCard
                icon={(props) => (
                  <svg
                    {...props}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                    />
                  </svg>
                )}
                title="Ads Deals"
                value={stats.adsDeals}
                iconColor="bg-orange-500/20"
                textColor="text-orange-500"
              />
            </div>

            {/* Sources of Acquisition */}
            <div className="bg-primary opacity-75 rounded-sm overflow-hidden p-6">
              <h2 className="text-xl text-start font-semibold text-white pb-2 border-b border-b-gray-600 mb-6">Sources of Acquisition</h2>
              
              <div className="space-y-4">
                {acquisitionSources.length > 0 ? (
                  acquisitionSources.map((source, index) => (
                    <ProgressBar 
                      key={index}
                      label={source.source}
                      percentage={source.percentage}
                      color={colorClasses[index % colorClasses.length]}
                    />
                  ))
                ) : (
                  <p className="text-white">No acquisition sources data available.</p>
                )}
              </div>
            </div>
            
            {/* Sales by Commercial */}
            <div className="bg-primary opacity-75 rounded-sm overflow-hidden p-6">
              <h2 className="text-xl text-start font-semibold text-white pb-2 border-b border-b-gray-600 mb-6">Sales by Commercial</h2>
              
              {salesByCommercial.length > 0 ? (
                <div className="space-y-6">
                  {salesByCommercial.map((person, index) => (
                    <div key={index} className="border-b border-[#2a3142] pb-4 last:border-b-0 last:pb-0">
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex flex-col items-start">
                          <span className="text-gray-300 font-medium">{person.name}</span>
                          {person.email && (
                            <p className="text-gray-500 text-sm">{person.email}</p>
                          )}
                        </div>
                        <div className="text-right">
                          <span className="text-green-400 font-medium">
                            ${person.amount.toLocaleString()}
                          </span>
                          <p className="text-gray-300 text-sm">
                            {person.wonDeals} won / {person.sales} total deals
                          </p>
                        </div>
                      </div>
                      
                      <div className="w-full bg-white/10 rounded-full h-2 mt-2">
                        <div
                          className="h-2 rounded-full bg-green-500"
                          style={{ 
                            width: `${person.sales > 0 ? (person.wonDeals / person.sales) * 100 : 0}%` 
                          }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-8">
                  <p className="text-gray-300">No sales data available</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SourceAcquisition;