// src/routes/routes.js

import MyProfile from "../pages/MyProfile";
import ResumeTraining from "../pages/ResumeTraining";
import Ranking from "../pages/Ranking";
import MyRewards from "../pages/MyRewards";
import Settings from "../pages/Settings";
import CourseContent from "../ResumeTrainingPage/CourseContent";
import QuizQuestionPage from "../pages/QuizQuestionPage";
import PaymentSuccess from "../pages/PaymentSuccess";
import CheckoutFifty from "../pages/CheckoutFifty";
import CheckoutFiftySuccess from "../pages/CheckoutFiftySuccess";
import CheckoutPayalUserData from "../pages/CheckoutPaypalUserData";
import CheckoutStripeUserData from "../pages/CheckoutStripeUserData";
import CheckoutPaypal from "../pages/CheckoutPaypal";
import Subscription from "../pages/Subscription";
import Checkout from "../pages/Checkout";

const UserRoutes = [
  {
    path: "/",
    exact: true,
    element: MyProfile,
  },
  {
    path: "/resume",
    exact: true,
    element: ResumeTraining,
  },
  {
    path: "/reward",
    exact: true,
    element: MyRewards,
  },
  {
    path: "/ranking",
    exact: true,
    element: Ranking,
  },
  {
    path: "/settings",
    exact: true,
    element: Settings,
  },
  {
    path: "/payment-success",
    exact: true,
    element: PaymentSuccess,
  },
  {
    path: "/checkoutFifty/payment-success",
    exact: true,
    element: CheckoutFiftySuccess,
  },
  {
    path: "/payment-success",
    exact: true,
    element: PaymentSuccess,
  },
  {
    path: "/resume/course-content/items/:id",
    exact: true,
    element: CourseContent,
  },
  {
    path: "/settings/profile-modify",
    exact: true,
    element: QuizQuestionPage,
  },
  {
    path: "/checkout/join",
    exact: true,
    element: CheckoutFifty,
  },
  {
    path: "/checkout/:id",
    exact: true,
    element: Checkout,
  },
  {
    path: "/checkout/paypal",
    exact: true,
    element: CheckoutPaypal,
  },
  {
    path: "/checkout/paypal/:id",
    exact: true,
    element: CheckoutPaypal,
  },
  
  {
    path: "checkout/success-paypal",
    exact: true,
    element: CheckoutPayalUserData,
  },
  {
    path: "checkout/subscription-success",
    exact: true,
    element: CheckoutStripeUserData,
  },
  {
    path: "checkout/subscription",
    exact: true,
    element: Subscription,
  },
];

export default UserRoutes;
