import React from "react";

const PriceCard = ({ priceData, selectedMethod }) => {
  return (
    <div className="rounded-lg price-card py-8 mb-4  bg-[#ffffff12]">
      <div className="w-full flex justify-between py-2 px-10 font-poppins">
        <h2 className="font-normal text-left text-white text-lg md:text-lg">
          Formation Chatgpt Academy
        </h2>
        <p
          className={`${
            priceData?.discountPercentage > 0 || priceData?.value > 0
              ? "line-through"
              : ""
          } text-left text-white font-normal text-xl`}
        >
          {/* {priceData?.originalPrice || priceData?.actualPrice} &#8364; */}
          {/* {selectedMethod ==="paypal" ? `4 x ${ Math.ceil(priceData.originalPrice / 4)} ` : priceData?.originalPrice || priceData?.actualPrice} &#8364; */}
          {selectedMethod ==="paypal" ? `4 x 400 ` : priceData?.originalPrice || priceData?.actualPrice} &#8364;
        </p>
      </div>
      {priceData?.discountPercentage || priceData?.value > 0 ? (
        <div className="w-full bg-[#060b26] py-2 px-10 flex justify-between z-20">
          <p className="text-left text-white text-lg md:text-lg">
            🚨 OFFRE LIMITEE (-
            {parseFloat(
              priceData?.discountPercentage || priceData?.value
            ).toFixed(0)}
            %)
          </p>
          <p className="text-left text-white font-normal text-xl">
            {priceData?.discountedPrice || priceData?.totalPrice}
          </p>
        </div>
      ) : (
        ""
      )}

      <div className="flex justify-between items-center py-2 px-10">
        <p className="text-left text-white text-lg">Taxe</p>
        <p className="text-left text-white text-lg">
          {priceData?.taxPercentage || 0}%
        </p>
      </div>

      <div className="h-[1px] mx-auto my-2 w-[90%] text-white bg-[#ffffff36]"></div>

      <div className="flex justify-between items-center py-1 px-10">
        <p className="text-left text-white text-lg">Montant total</p>
        <p className="text-left text-white text-lg">
          {/* {priceData?.totalPrice} &#8364; */}
          {/* {selectedMethod === "paypal" ? "1100.00" : priceData?.totalPrice} &#8364; */}
          {selectedMethod === "paypal" ? "1600.00" : priceData?.totalPrice} &#8364;
        </p>
      </div>
    </div>
  );
};

export default PriceCard;
