import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,

} from "firebase/firestore";
import { db } from "../firebase/config";
import { Eye, Search } from "lucide-react";
import Header from "./Header";

// Stat Card Component with themed colors
const StatCard = ({
  icon: Icon,
  title,
  value,
  iconColor = "bg-primaryBlue/20",
  textColor = "text-primaryBlue",
}) => (
  <div className="bg-white/10 backdrop-blur-sm rounded-sm border border-borderColor p-6 hover:shadow-lg transition-shadow duration-200">
    <div className="flex items-center space-x-4">
      <div className={`p-3 rounded-full ${iconColor}`}>
        <Icon className={`w-6 h-6 ${textColor}`} />
      </div>
      <div>
        <p className="text-sm font-medium text-white/60">{title}</p>
        <h3 className="text-2xl text-left font-bold text-white">{value}</h3>
      </div>
    </div>
  </div>
);

// Pagination Component
const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="flex items-center justify-center px-6 py-4 bg-primary opacity-75 border-t border-borderColor">
    <button
      onClick={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 1}
      className="px-4 py-2 text-xl font-medium text-white/60 disabled:opacity-50 disabled:cursor-not-allowed hover:text-white transition-colors"
    >
      &lt;
    </button>
    <span className="text-sm text-white/60">
      Page {currentPage} of {totalPages}
    </span>
    <button
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
      className="px-4 py-2 text-xl font-medium text-white/60 disabled:opacity-50 disabled:cursor-not-allowed hover:text-white transition-colors"
    >
      &gt;
    </button>
  </div>
);

const FinanceInsights = ({setIsSidebarOpen, isSidebarOpen}) => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalAmount: 0,
  });
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);

      // Fetch users
      const usersSnapshot = await getDocs(collection(db, "users"));
      const userData = usersSnapshot.docs.map((doc) => {
        const user = { id: doc.id, ...doc.data() };
        return user;
      });

      const usersWithPayments = [];

      // Fetch payment details for each user
      for (const user of userData) {
        const paymentSnapshot = await getDocs(
          collection(db, "users", user.id, "paymentDetails")
        );
        const userPayments = paymentSnapshot.docs.map((paymentDoc) =>
          paymentDoc.data()
        );
        const totalPaid = userPayments.length > 0 ? userPayments[0].amount : 0;
        const paymentDate = userPayments.length > 0
          ? userPayments[0]?.createdAt?.toDate().toLocaleDateString()
          : "N/A";
        const paymentMethod = userPayments.length > 0
          ? userPayments[0].paymentMethod
          : "N/A";
        const receiptUrl = userPayments.length > 0
          ? userPayments[0].receiptUrl
          : "N/A";

        usersWithPayments.push({
          ...user,
          totalPaid,
          paymentDate,
          paymentMethod,
          receiptUrl,
        });
      }

      const totalAmout = usersWithPayments?.length > 0 && usersWithPayments.reduce((acc, sum) => acc + +sum.totalPaid, 0)


      setStats({
        totalUsers: userData.length,
        totalAmount: parseFloat(totalAmout / 100).toFixed(2),
      });

      setUsers(usersWithPayments);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setLoading(false);
    }
  };

  const filteredUsers = users.filter((user) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      (user.pseudo || user.displayName || "")
        .toLowerCase()
        .includes(searchLower) || user.email.toLowerCase().includes(searchLower)
    );
  });

  const totalPages = Math.ceil(filteredUsers.length / recordsPerPage);
  const startIndex = (currentPage - 1) * recordsPerPage;
  const paginatedUsers = filteredUsers.slice(
    startIndex,
    startIndex + recordsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };



  return (
    <div className="rounded-sm font-poppins ">
      <div className="w-[100%]">
        <Header PageName={"Finance"} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      </div>
      <div className="mx-auto space-y-4">
        {/* Stats Grid */}
        {
          loading ? <div className="rounded-sm border-[#fefefe30] border h-[90vh] flex justify-center items-center bg-primary opacity-75 font-poppins ">
            <div className="animate-spin w-16 h-16 border-4 border-primaryBlue border-t-transparent rounded-full" />
          </div> : <>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <StatCard
                icon={(props) => (
                  <svg
                    {...props}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                )}
                title="Total Users"
                value={stats.totalUsers}
                iconColor="bg-purple-500/20"
                textColor="text-purple-500"
              />
              <StatCard
                icon={(props) => (
                  <svg
                    {...props}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 1v22m-4-6h8a4 4 0 000-8h-8a4 4 0 010-8h8"
                    />
                  </svg>
                )}
                title="Total Amount"
                value={stats.totalAmount}
                iconColor="bg-green-500/20"
                textColor="text-green-500"
              />
            </div>

            {/* Users Table */}
            <div className="bg-primary opacity-75 rounded-sm border border-[#f3f3f320] overflow-hidden">
              <div className="p-3 px-4 border-b header-container border-[#f3f3f320]">
                <div className="flex justify-between items-center">
                  <h2 className="text-lg font-semibold text-white">
                    User Payment Details
                  </h2>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCurrentPage(1);
                      }}
                      className="pl-10 pr-4 py-2 bg-white/5 border border-[#f3f3f320] rounded-sm text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-primaryBlue/50"
                    />
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white/40 w-4 h-4" />
                  </div>
                </div>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-white/5">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                        User
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                        Email
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                        Amount Paid
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                        Payment Date
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                        Payment Method
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                        Receipt
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-[#fefefe18]">
                    {paginatedUsers.map((user) => (
                      <tr key={user.id} className="hover:bg-white/5">
                        <td className="px-6 py-4 text-left whitespace-nowrap">
                          <div className="text-sm font-medium text-white">
                            {user.pseudo || user.displayName}
                          </div>
                        </td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">
                          <div className="text-sm text-white/60"> {user.email}</div>
                        </td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">
                          <div className="text-sm text-green-500">
                            {" "}
                            €{parseFloat(user.totalPaid / 100).toFixed(2) || 0}
                          </div>
                        </td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">
                          <div className="text-sm text-white/60">
                            {user.paymentDate}
                          </div>
                        </td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">
                          <div className="text-sm text-white/60">{"Stripe"}</div>
                        </td>
                        <td className="px-6 py-4 text-left whitespace-nowrap">
                          <div className="text-sm text-white/60">
                            <a
                              href={user.receiptUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-primaryBlue hover:text-primaryBlue/80"
                            >
                              <Eye className="text-[#E2B80F]" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default FinanceInsights;
