import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { useFirebase } from "../context/firebaseContext";
import {
    TrashIcon,
    PencilIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { db } from "../firebase/config";
import { collection, onSnapshot } from "firebase/firestore";
import Modal from "../Components/Modal";
import toast from "react-hot-toast";
import CopyableLink from "../Components/CopyableLink";
import { Search } from "lucide-react";
import PaypalDiscountLinkForm from "./PaypalDiscountLinkForm";

const PaypalDiscountLink = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const [paypalDiscountLinks, setPaypalDiscountLinks] = useState([]);
    const [filteredPaypalDiscountLinks, setFilteredPaypalDiscountLinks] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedPaypalDiscountLink, setSelectedPaypalDiscountLink] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { deletePaypalDiscountLink } = useFirebase();

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [paypalDiscountLinksPerPage] = useState(10);

    useEffect(() => {
        const singupLinkCollection = collection(db, "paypalDiscounts");
        const unsubscribe = onSnapshot(singupLinkCollection, (snapshot) => {
            const singupLinkList = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setPaypalDiscountLinks(singupLinkList);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const results = paypalDiscountLinks.filter(
            (singupLink) =>
                singupLink.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredPaypalDiscountLinks(results);
        setCurrentPage(1); // Reset to first page when search results change
    }, [searchTerm, paypalDiscountLinks]);

    const handleAdd = () => {
        setSelectedPaypalDiscountLink(null);
        setIsModalOpen(true);
    };

    const handleUpdate = (singupLink) => {
        setSelectedPaypalDiscountLink(singupLink);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedPaypalDiscountLink(null);
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this singupLink?")) {
            await deletePaypalDiscountLink(id);
            toast.success("SingupLink deleted successfully...", {
                position: "top-center",
            });
        }
    };

    // Pagination logic
    const indexOfLastSingupLink = currentPage * paypalDiscountLinksPerPage;
    const indexOfFirstSingupLink = indexOfLastSingupLink - paypalDiscountLinksPerPage;
    const currentPaypalDiscountLinks = filteredPaypalDiscountLinks.slice(
        indexOfFirstSingupLink,
        indexOfLastSingupLink
    );

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="w-full rounded-sm h-full">
            <div className="flex flex-col">
                <Header
                    PageName={"paypalDiscountLinks"}
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                />
            </div>
            <div className="">
                <div className="w-full p-6 bg-primary opacity-75 rounded-sm">
                    <div className="flex justify-between items-center mb-4">
                        <div className="relative">
                        <input
                            type="text"
                            placeholder="Search..."
                            className="pl-10 pr-4 py-2 bg-white/5 border border-borderColor rounded-sm text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-primaryBlue/50"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white/40 w-4 h-4" />

                        </div>
                        <button
                            onClick={handleAdd}
                            className="px-4 py-2 bg-gradient-to-r from-[#113be1] via-[#4268ff] to-[#113be1] text-white rounded-sm"
                        >
                            Add Paypal Discount Link
                        </button>
                    </div>
                    <table className="min-w-full divide-y divide-[#fefefe20]">
                        <thead className="bg-[#1c253600]">
                            <tr>
                                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                                    Title
                                </th>
                               
                                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                                    Discount Url
                                </th>
                                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                                    Price
                                </th>
                               
                                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-[#060B26] divide-y divide-gray-700">
                            {currentPaypalDiscountLinks?.map((paypalDiscountLink) => (
                                <tr key={paypalDiscountLink.id}>
                                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                                        {paypalDiscountLink.title}
                                    </td>
                                    <td
                                        className="text-left py-4 whitespace-nowrap text-sm text-white"
                                    >
                                        <CopyableLink link={paypalDiscountLink.paypalDiscountUrl} expiry={paypalDiscountLinks.expiry} />
                                        {/* {paypalDiscountLink.privateUrl} */}
                                    </td>
                                    
                                    <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                                        {paypalDiscountLink.price}
                                    </td>
                                   
                                   
                                    <td className="text-left py-4 whitespace-nowrap text-sm font-medium">
                                        <button
                                            onClick={() => handleUpdate(paypalDiscountLink)}
                                            className="text-indigo-600 hover:text-indigo-900 mr-2"
                                        >
                                            <PencilIcon className="h-5 w-5" />
                                        </button>
                                        <button
                                            onClick={() => handleDelete(paypalDiscountLink.id)}
                                            className="text-red-600 hover:text-red-900"
                                        >
                                            <TrashIcon className="h-5 w-5" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Pagination */}
                    <div className="mt-6 flex flex-col items-center">
                        <div className="flex items-center justify-center space-x-2">
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="px-3 py-2 rounded-md bg-[#1C2536] text-white hover:bg-[#2C3546] disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                <ChevronLeftIcon className="h-5 w-5" />
                            </button>
                            {[
                                ...Array(
                                    Math.ceil(filteredPaypalDiscountLinks.length / paypalDiscountLinksPerPage)
                                ).keys(),
                            ].map((number) => (
                                <button
                                    key={number + 1}
                                    onClick={() => paginate(number + 1)}
                                    className={`px-3 py-2 rounded-md text-sm font-medium ${currentPage === number + 1
                                            ? "bg-primaryBlue text-white"
                                            : "bg-[#1C2536] text-white hover:bg-[#2C3546]"
                                        }`}
                                >
                                    {number + 1}
                                </button>
                            ))}
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={
                                    currentPage ===
                                    Math.ceil(filteredPaypalDiscountLinks.length / paypalDiscountLinksPerPage)
                                }
                                className="px-3 py-2 rounded-md bg-[#1C2536] text-white hover:bg-[#2C3546] disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                <ChevronRightIcon className="h-5 w-5" />
                            </button>
                        </div>
                    </div>
                </div>

                {isModalOpen && (
                    <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                        <PaypalDiscountLinkForm paypalDiscountLink={selectedPaypalDiscountLink} onClose={handleCloseModal} />
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default PaypalDiscountLink;
