import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useGlobal } from "../context/GlobalContext";
import Header from "../Components/Header";
import CourseLeftNav from "./CourseLeftNav";
import QuizQuestionPage from "../pages/QuizQuestionPage";
import QuizSummary from "../ResumeTrainingPage/QuizSummary";
import Player from "../Components/Player";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import QuizDialogue from "../Components/QuizDialogue";
import { SvgIcon } from "../Components/SvgIcon";
import NextLessonModal from "../Components/NextLessonModal";

export default function CourseContent({ setIsSidebarOpen, isSidebarOpen }) {
  const {
    courseData,
    userProgress,
    handleItemCompletion,
    currentItem,
    setCurrentItem,
    findNextItem,
  } = useGlobal();

  const { id } = useParams();

  let { state } = useLocation();

  const sectionName = state?.sectionName;
  const moduleName = state?.moduleName;
  const navigate = useNavigate();

  const [isVideoCompleted, setIsVideoCompleted] = useState(
    userProgress?.currentItem?.status
  );
  const [currentSectionId, setCurrentSectionId] = useState(null);
  const [currentModuleId, setCurrentModuleId] = useState(null);
  const [currentSectionTitle, setCurrentSectionTitle] = useState(null);
  const [isSelected, setIsSelected] = useState(null);
  const [videoSources, setVideoSources] = useState([]);

  const [isQuizDialogueOpen, setIsQuizDialogueOpen] = useState(false);
  const [nextQuizItem, setNextQuizItem] = useState(null);
  const [isQuizStarted, setIsQuizStarted] = useState(false);

  const [showNextLessonModal, setShowNextLessonModal] = useState(false);
  const [nextLesson, setNextLesson] = useState(null);

  useEffect(() => {
    const section = courseData?.find((s) => s.title === sectionName);
    const module = section?.modules.find((m) => m.title === moduleName);
    const content = module?.content.find((c) => c.id === id);

    if (
      content?.type === "quiz" &&
      isQuizStarted &&
      currentItem?.type === "quiz" &&
      currentItem?.id !== content.id
    ) {
      setIsQuizDialogueOpen(true);
      setNextQuizItem(content);
      handleStartQuiz()
    } else {
      setCurrentSectionId(section?.id || userProgress?.currentSection?.id);
      setCurrentModuleId(module?.id || userProgress?.currentModule?.id);
      setCurrentSectionTitle(
        section?.title || userProgress?.currentSection?.title
      );
      if (content) {
        setCurrentItem(content);
        setIsSelected(content);
        if (content.type !== "quiz") {
          setIsQuizStarted(false);
      handleStartQuiz()

        }
      } else {
        setCurrentItem(userProgress?.currentItem);
      handleStartQuiz()

      }
    }
  }, [
    courseData,
    sectionName,
    moduleName,
    id,
    setCurrentItem,
    userProgress,
    isQuizStarted,
    currentItem,
  ]);

  // const handleVideoComplete = useCallback(
  //   async (currentItem, sectionId, currentModuleId) => {
  //     console.log("Video ended, handling completion");

  //     setIsVideoCompleted(true);
  //     await handleItemCompletion(currentItem, sectionId, currentModuleId);

  //     const nextItem = findNextItem(currentItem, sectionId);
  //     if (nextItem) {
  //       navigate(
  //         `/resume/course-content/${sectionName}/${nextItem.module.title}/${nextItem.type}/${nextItem.id}`
  //       );
  //     }
  //   },
  //   [handleItemCompletion, findNextItem, navigate, sectionName]
  // );
  const handleVideoComplete = useCallback(
    async (currentItem, sectionId, currentModuleId) => {
      console.log("Video ended, handling completion");

      setIsVideoCompleted(true);
      await handleItemCompletion(currentItem, sectionId, currentModuleId);

      const nextItem = findNextItem(currentItem, sectionId);
      if (nextItem && nextItem.type === "video") {
        setNextLesson(nextItem);
        setShowNextLessonModal(true);
      } else {
        // If there's no next video item, navigate immediately
        if (nextItem) {
          navigate(
            `/resume/course-content/items/${nextItem.id}`,
            {
              state: {
                sectionName,
                moduleName: nextItem.module.title,
                type: nextItem.type,
              },
            }

            // ${sectionName}/${nextItem.module.title}/${nextItem.type}/${nextItem.id}`,
          );
        }
      }
    },
    [handleItemCompletion, findNextItem, navigate, sectionName]
  );
  const handleNextLessonModalClose = (shouldNavigate = true) => {
    setShowNextLessonModal(false);

    if (shouldNavigate && nextLesson) {
      navigate(
        // `/resume/course-content/${sectionName}/${nextLesson.module.title}/${nextLesson.type}/${nextLesson.id}`

        `/resume/course-content/items/${nextLesson.id}`,
        {
          state: {
            sectionName,
            moduleName: nextLesson.module.title,
            type: nextLesson.type,
          },
        }
      );
    }
  };
  const handleNextItem = (currentItem, sectionId) => {
    const nextItem = findNextItem(currentItem, sectionId);
    if (nextItem) {
      navigate(
        // `/resume/course-content/${sectionName}/${nextItem.module.title}/${nextItem.type}/${nextItem.id}`

        `/resume/course-content/items/${nextItem.id}`,
        {
          state: {
            sectionName,
            moduleName: nextItem.module.title,
            type: nextItem.type,
          },
        }
      );
    }
  };

  const formatURL = (url, quality) => {
    if (!url) return url;
    if (quality === 1080) return url;

    return url.replace("1080p.", `${quality}p.`);
  };

  const getVideoSources = useCallback(() => {
    const item = currentItem;

    if (item?.sizes) {
      const sizes = item.sizes
        .sort((a, b) => b - a)
        .map((size) => ({
          src: formatURL(item.url, size),
          type: "video/mp4",
          size: size,
        }));
      setVideoSources(sizes);
    }
  }, [currentItem]);

  useEffect(() => {
    getVideoSources();
    handleStartQuiz()
  }, [currentItem, getVideoSources]);

  const plyrOptions = {
    controls: [
      "play-large",
      "play",
      "progress",
      "current-time",
      "mute",
      "volume",
      "settings",
      "pip",
      "airplay",
      "fullscreen",
    ],
    settings: ["quality", "speed", "loop"],
  };

  const handleQuizDialogueConfirm = () => {
    setIsQuizStarted(false);
    setCurrentItem(nextQuizItem);
    setIsSelected(nextQuizItem);
    setIsQuizDialogueOpen(false);
  };

  const handleQuizDialogueClose = () => {
    setIsQuizDialogueOpen(false);
  };

  const handleStartQuiz = () => {
    setIsQuizStarted(true);
  };

  const renderVideoPlayer = useCallback(
    () => (
      <div className="relative h-0 pt-[56.25%] rounded-[9.83px] overflow-hidden">
        <Player
          plyrOptions={plyrOptions}
          videoSource={videoSources}
          currentItem={currentItem}
          moduleId={currentModuleId}
          sectionId={currentSectionId}
          handleVideoEnd={handleVideoComplete}
        />
      </div>
    ),
    [
      currentItem,
      videoSources,
      handleVideoComplete,
      currentSectionId,
      currentModuleId,
    ]
  );

  const renderContent = () => {
    if (currentItem?.type === "quiz") {
      return isQuizStarted ? (
        <QuizQuestionPage
          quiz={currentItem}
          sectionId={currentSectionId}
          sectionName={currentSectionTitle}
          moduleId={currentModuleId}
        />
      ) : (
        <QuizSummary
          quiz={currentItem}
          handleStartQuiz={handleStartQuiz}
          isLoading={false}
        />
      );
    }

    const title = currentItem?.title || userProgress?.currentItem?.title;
    const description =
      currentItem?.description || userProgress?.currentItem?.description;

    return (
      <div className="flex flex-col xl:h-[calc(100vh-123px)] 2xl:w-[70%] border-[1px] border-borderColor bg-bgColor rounded-[15px] p-[15px] overflow-hidden overflow-y-auto">
        <h2 className="text-left font-semibold text-[15px] lg:text-[20px] text-white">
          <span className="hidden xl:inline-block text-center lg:text-[13px] xl:[18px] xl:text-center mb-7">
            {title}
          </span>
          <div className="flex justify-between items-center gap-6 sm:gap-12 lg:hidden mb-7">
            <FaAngleLeft
              className="text-white w-4 h-4 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <span className="text-[13px] lg:text-[20px] text-center">
              {title}
            </span>
            <FaAngleRight
              className="text-white w-4 h-4 cursor-pointer"
              onClick={() => handleNextItem(currentItem, currentSectionId)}
            />
          </div>
        </h2>
        {renderVideoPlayer()}
        <div className="flex flex-col gap-[10px] mt-4">
          <h2 className="text-white text-lg font-medium leading-normal text-left lg:text-[13px] xl:text-[15px]">
            Description
          </h2>
          <div
            className="text-left text-[12px] lg:text-[13px] xl:text-[15px] text-[#A0AEC0]"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <div className="w-full mt-5 xl:hidden">
          <button
            className={`primary-btn flex w-full p-[10px] justify-center items-center rounded-[7px] text-white font-bold text-[12px] uppercase bg-gradient-to-r from-[#113BE1] via-[#4268FF] to-[#113BE1]`}
          >
            Commencer la vidéo
          </button>
        </div>
        {isVideoCompleted && (
          <div className="flex start items-center gap-2 mt-10">
            <SvgIcon name="tickMark" />
            <p className="text-xs">Tu as consulté cette vidéo</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col h-[100%]">
      <div className="w-full">
        <Header
          PageName="Formation"
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>
      <div className="flex gap-6">
        {window.innerWidth > 1538 ? (
          <>
            <div className="course-left-nav">
              <CourseLeftNav />
            </div>
            {renderContent()}
          </>
        ) : (
          <>
            {isSelected ? (
              <div className="flex w-full flex-col">
                <div
                  className="flex items-center gap-[10px] mb-7 justify-start"
                  onClick={() => {
                    setIsSelected(null);
                    navigate("/resume");
                  }}
                >
                  <FaAngleLeft className="text-white w-3 h-3" />
                  <span className="text-[14px] font-medium text-white">
                    Revenir au sommaire
                  </span>
                </div>
                {renderContent()}
              </div>
            ) : (
              <div className="course-left-nav">
                <CourseLeftNav />
              </div>
            )}
          </>
        )}
      </div>

      <QuizDialogue
        isOpen={isQuizDialogueOpen}
        onClose={handleQuizDialogueClose}
        onConfirm={handleQuizDialogueConfirm}
      />
      <NextLessonModal
        isOpen={showNextLessonModal}
        onClose={handleNextLessonModalClose}
        nextItem={nextLesson}
      />
    </div>
  );
}
