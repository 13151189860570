import React, { useState, useEffect } from "react";
import { useFirebase } from "../context/firebaseContext";
import toast from "react-hot-toast";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { v4 as uuidv4 } from "uuid";
const ENV = process.env.REACT_APP_ENV;
const PaypalDiscountLinkForm = ({ paypalDiscountLink, onClose }) => {
  const [formData, setFormData] = useState({
    title: "",
    price: null,
    paypalDiscountUrl: "",
    randomId: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { updatePaypalDiscountLink, addPaypalDiscountLink } = useFirebase();
  useEffect(() => {
    // Generate URL with random ID
    const randomId = uuidv4().slice(0, 20);
    // Generate a 20-character random ID
    const baseUrl =
      ENV === "staging"
        ? "https://staging-chatgpt-academy-8b1b4.web.app/checkout/paypal"
        : "https://training.chatgpt-academy.fr/checkout/paypal";
    const paypalDiscountUrl = `${baseUrl}/${randomId}`;

    if (paypalDiscountLink) {
      setFormData({
        title: paypalDiscountLink.title || "",
        price: +paypalDiscountLink.price,
        paypalDiscountUrl: paypalDiscountLink.paypalDiscountUrl || paypalDiscountUrl,
        randomId: paypalDiscountLink.randomId,
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        paypalDiscountUrl,
        randomId,
      }));
    }
  }, []);
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.title) newErrors.title = "Title is required";
    if (!formData.price) newErrors.price = "Price is required";
    return Object.keys(newErrors).length === 0;
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      console.log("Form not validated...");
      return;
    }

    setIsLoading(true);
    try {
      const paypalDiscountLinkData = {
        title: formData.title,
        price: +formData.price,
        paypalDiscountUrl: formData.paypalDiscountUrl,
        randomId: formData.randomId,
      };

      if (paypalDiscountLink) {
        const res = await updatePaypalDiscountLink(paypalDiscountLink.id, paypalDiscountLinkData);
        res.status === "success"
          ? toast.success(res.message, {
              position: "top-center",
            })
          : toast.error(res.message, {
              position: "top-center",
            });
      } else {
        
        const res = await addPaypalDiscountLink(paypalDiscountLinkData);
        res.status === "success"
          ? toast.success(res.message, {
              position: "top-center",
            })
          : toast.error(res.message, {
              position: "top-center",
            });
      }
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(error.message, {
        position: "top-center",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="p-6 bg-[#060B26] rounded-xl border-[#ffffff29] border text-white"
    >
      <h2 className="text-xl text-start font-medium py-4 border-b mb-6">
        {paypalDiscountLink ? "Update PaypalDiscountLink" : "Add PaypalDiscountLink"}
      </h2>

      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="title" className="block text-sm font-medium mb-1">
          Title
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {errors.title && (
          <p className="mt-1 text-sm text-red-500">{errors.title}</p>
        )}
      </div>
      
      <div className="mb-4 flex-col items-start">
        <label
          htmlFor="value"
          className="block text-start text-sm font-medium mb-1"
        >
          Price
        </label>
        <input
          type="number"
          value={formData.price}
          id="price"
          name="price"
          price={formData.price}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        {errors.price && (
          <p className="mt-1 text-sm text-red-500">{errors.price}</p>
        )}
      </div>
      
      
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="paypalDiscountUrl" className="block text-sm font-medium mb-1">
          Paypal Discount URL
        </label>
        <input
          type="text"
          id="paypalDiscountUrl"
          name="paypalDiscountUrl"
          value={formData.paypalDiscountUrl}
          readOnly
          className="w-full p-2 text-start text-white bg-[#1C2536] h-full rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isLoading}
          className={`px-4 py-2 bg-primaryBlue text-white rounded ${
            isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-600"
          }`}
        >
          {isLoading
            ? "Processing..."
            : paypalDiscountLink
            ? "Update Link"
            : "Add Link"}
        </button>
      </div>
    </form>
  );
};

export default PaypalDiscountLinkForm;
