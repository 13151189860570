import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { useFirebase } from "../context/firebaseContext";
import { TrashIcon, PencilIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { db } from "../firebase/config";
import { collection, onSnapshot } from "firebase/firestore";
import Modal from "../Components/Modal";
import ModuleForm from "../Components/ModuleForm.";
import toast from "react-hot-toast";
import { Search } from "lucide-react";

const Modules = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const [modules, setModules] = useState([]);
  const [filteredModules, setFilteredModules] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedModule, setSelectedModule] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { deleteModule } = useFirebase();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [modulesPerPage] = useState(10);

  useEffect(() => {
    const moduleCollection = collection(db, "modules");
    const unsubscribe = onSnapshot(moduleCollection, (snapshot) => {
      const moduleList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setModules(moduleList);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const results = modules.filter(module =>
      module.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      module.createdBy.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredModules(results);
    setCurrentPage(1); // Reset to first page when search results change
  }, [searchTerm, modules]);

  const handleAdd = () => {
    setSelectedModule(null);
    setIsModalOpen(true);
  };

  const handleUpdate = (module) => {
    setSelectedModule(module);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedModule(null);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this module?')) {
      await deleteModule(id);
      toast.success("Module deleted successfully...", {
        position: 'top-center'
      });
    }
  };

  // Pagination logic
  const indexOfLastModule = currentPage * modulesPerPage;
  const indexOfFirstModule = indexOfLastModule - modulesPerPage;
  const currentModules = filteredModules.slice(indexOfFirstModule, indexOfLastModule);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="w-full rounded-sm h-full">
      <div className="flex flex-col">
        <Header
          PageName={"modules"}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>
      <div className="rounded-sm ">
        <div className="w-full p-6 bg-primary opacity-75 rounded-sm">
          <div className="flex justify-between items-center mb-4">
            <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              className="pl-10 pr-4 py-2 bg-white/5 border border-borderColor rounded-sm text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-primaryBlue/50"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white/40 w-4 h-4" />

            </div>
            <button
              onClick={handleAdd}
              className="px-4 py-2 bg-gradient-to-r from-[#113be1] via-[#4268ff] to-[#113be1] text-white rounded-sm"
            >
              Add Module
            </button>
          </div>
          <table className="min-w-full divide-y divide-[#fefefe50]">
            <thead className="bg-[#1c253600]">
              <tr>
                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Title</th>
                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Description</th>
                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Order</th>
                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Section</th>
                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Created By</th>
                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Status</th>
                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className=" divide-y divide-[#fefefe18]">
              {currentModules.map((module) => (
                <tr key={module.id}>
                  <td className="text-left py-4 whitespace-nowrap text-sm text-white">{module.title}</td>
                  <td title={module?.description} className="text-left py-4 max-w-4 pr-3 truncate whitespace-nowrap text-sm text-white">{module.description}</td>
                  <td className="text-left py-4 whitespace-nowrap text-sm text-white">{module.order}</td>
                  <td className="text-left py-4 whitespace-nowrap text-sm text-white">{module?.section?.title}</td>
                  <td className="text-left py-4 whitespace-nowrap text-sm text-white">{module.createdBy}</td>
                  <td className="text-left py-4 whitespace-nowrap text-sm text-white">{module.status}</td>
                  <td className="text-left py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => handleUpdate(module)}
                      className="text-indigo-600 hover:text-indigo-900 mr-2"
                    >
                      <PencilIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(module.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          
          {/* Pagination */}
          <div className="mt-6 flex flex-col items-center">
            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-2 rounded-md bg-[#1C2536] text-white hover:bg-[#2C3546] disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronLeftIcon className="h-5 w-5" />
              </button>
              {[...Array(Math.ceil(filteredModules.length / modulesPerPage)).keys()].map((number) => (
                <button
                  key={number + 1}
                  onClick={() => paginate(number + 1)}
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    currentPage === number + 1
                      ? 'bg-primaryBlue text-white'
                      : 'bg-[#1C2536] text-white hover:bg-[#2C3546]'
                  }`}
                >
                  {number + 1}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === Math.ceil(filteredModules.length / modulesPerPage)}
                className="px-3 py-2 rounded-md bg-[#1C2536] text-white hover:bg-[#2C3546] disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            <ModuleForm module={selectedModule} onClose={handleCloseModal} />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Modules;