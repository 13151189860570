import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase/config";
import Header from "../Components/Header";

const SalesByCommercial = ({ setIsSidebarOpen, isSidebarOpen }) => {
  const [loading, setLoading] = useState(true);
  const [salesData, setSalesData] = useState([]);

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        setLoading(true);
        
        // Get reference to the salesDeals collection
        const salesDealsRef = collection(db, "salesDeals");
        
        // Create a query to get all deals
        const q = query(salesDealsRef);
        
        // Execute the query
        const querySnapshot = await getDocs(q);
        
        // Process the data to group by deal owner
        const dealsByOwner = {};
        
        querySnapshot.forEach((doc) => {
          const deal = doc.data();
          
          // Skip if no deal owner
          if (!deal.deal_owner_name) return;
          
          // Initialize owner in map if not exists
          if (!dealsByOwner[deal.deal_owner_name]) {
            dealsByOwner[deal.deal_owner_name] = {
              name: deal.deal_owner_name,
              email: deal.deal_owner_email || "",
              id: deal.deal_owner_id || "",
              sales: 0,
              amount: 0,
              wonDeals: 0,
            };
          }
          
          dealsByOwner[deal.deal_owner_name].sales += 1;
          
          if (deal.status === "won" && deal.amountSpent) {
            const amount = parseFloat(deal.amountSpent) || 0;
            dealsByOwner[deal.deal_owner_name].amount += amount;
            dealsByOwner[deal.deal_owner_name].wonDeals += 1;
          }
        });
        
        const sortedSalesData = Object.values(dealsByOwner)
          .sort((a, b) => b.amount - a.amount);
        
        setSalesData(sortedSalesData);
      } catch (error) {
        console.error("Error fetching sales data:", error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchSalesData();
  }, []);

  return (
    <div className="rounded-sm font-poppins">
      <div className="w-[100%]">
        <Header PageName={"sales-by-commercial"} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      </div>
      <div className="mx-auto space-y-4">
        {loading ? (
          <div className="rounded-sm border-[#ffffff55] border h-[90vh] flex justify-center items-center bg-primary opacity-75 font-poppins">
            <div className="animate-spin w-16 h-16 border-4 border-primaryBlue border-t-transparent rounded-full" />
          </div>
        ) : (
          <div className="bg-primary opacity-75 rounded-sm overflow-hidden p-6">
            <h2 className="text-2xl text-start font-semibold text-white pb-2 border-b border-b-gray-600 mb-6">Sales by Commercial</h2>
            
            {salesData.length > 0 ? (
              <div className="space-y-6">
                {salesData.map((person, index) => (
                  <div key={index} className="border-b border-[#2a3142] pb-4 last:border-b-0 last:pb-0">
                    <div className="flex justify-between items-center mb-2">
                      <div>
                        <span className="text-white text-lg font-medium">{person.name}</span>
                        {person.email && (
                          <p className="text-gray-400 text-sm">{person.email}</p>
                        )}
                      </div>
                      <div className="text-right">
                        <span className="text-green-400 font-medium">
                          ${person.amount.toLocaleString()}
                        </span>
                        <p className="text-gray-300 text-sm">
                          {person.wonDeals} won / {person.sales} total deals
                        </p>
                      </div>
                    </div>
                    
                    <div className="w-full bg-white/10 rounded-full h-2 mt-2">
                      <div
                        className="h-2 rounded-full bg-green-500"
                        style={{ 
                          width: `${person.sales > 0 ? (person.wonDeals / person.sales) * 100 : 0}%` 
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-8">
                <p className="text-gray-300">No sales data available</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesByCommercial;